import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

// mui components
import { 
  Alert,
	Box, 
  Container,
  FormGroup,
  Grid,
  Link,
  Snackbar,
  Stack,
	TextField,
	Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
// context
import { robotConnContext } from '../../contexts/ConnectContext';

// components
import LoadingButton, { Spinner } from '../../components/LoadingButton';
import WssConnChecker from "../../components/workspace/Robot/wssConnChecker";
import { ROBOT_PORT_HTTP } from "../../config";

// images
import q9_intro from '../../assets/images/q9/q9_intro2.png';

import { IS_LOCAL, IS_LOGGING_ON } from "../../config";
import { Logger } from "../../logger";

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[0],
  width: '100%',
  minHeight: '50vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledTextField = styled(TextField)(({theme}) => ({
  borderColor: theme.palette.secondary.main,
  width: "250px",
  height: "55px"
}));

const vertical = 'bottom', horizontal = 'center';

export default function HomeConfig() {

  const { setIsReadyContext, setRobotIPContext } = useContext(robotConnContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	
	const [robotIP, setRobotIP] = useState(IS_LOCAL === true ? '127.0.0.1' : '');
  const [loadingIPcheck, setLoadingIPcheck] = useState(false);
  const [buttonIPcheckText, setButtonIPcheckText] = useState('Verify Connection');
  const [isIPchecked, setIsIPchecked] = useState(false);  
  const [openIPcheckedMsg, setOpenIPcheckedMsg] = useState(false);
  const [isIpError, setIsIpError] = useState(false);	

  const [otpNumber, setOtpNumber] = useState('');
  const [loadingOTPcheck, setLoadingOTPcheck] = useState(false);
  const [buttonOTPcheckText, setButtonOTPcheckText] = useState('Verify OTP');
	const [isVerifiedOtp, setIsVerifiedOtp] = useState(false);	
  const [openOTPcheckedMsg, setOpenOTPcheckedMsg] = useState(false);
  const [isOtpError, setIsOtpError] = useState(false);	

  const [loadingDownloadCerts, setLoadingDownloadCerts] = useState(false);
  const [openDownloadCertsDoneMsg, setOpenDownloadCertsDoneMsg] = useState(false);

  const [checkWssStatus, setCheckWssStatus] = useState(false);
  const [wssConnStatus, setWssConnStatus] = useState('closed'); 

  useEffect(() => {    
    if(robotIP === '127.0.0.1') {
      setIsIPchecked(true);
      setIsVerifiedOtp(true); 
      setRobotIPContext(robotIP);
    }
  }, [])
  
  const handleVerifyRobotIP = async(e) => {				
    setLoadingIPcheck(true);
    setIsIpError(false);
    e.preventDefault();	    
		let response;
		let url = 'https://' + robotIP + ':' + ROBOT_PORT_HTTP + '/api/otp';
		Logger(`send request for checking ip address and generating otp: ${url}`);

		try {
			response = await axios({
				method: "get",
				url: url,
        timeout: 3000,
				headers: {
					"Cache-Control": "no-cache",
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				responseType: "json",
			});
		} catch (error) {
      setLoadingIPcheck(false);
      setIsIPchecked(false);
      setOpenIPcheckedMsg(false);
      setIsIpError(true);
      if(error.code === 'ECONNABORTED'){
        Logger(`Timed out while checking ip address: ${error}`);        
      } else {
        Logger(`Error while checking ip address: ${error}`);
      }    
		}    	
		
    if(response && response.data.message === "success") {
      Logger(`response from bric bridge: ${response.data.message}`);      
      setLoadingIPcheck(false);
      setIsIPchecked(true);
      setOpenIPcheckedMsg(true);
      setIsIpError(false);
      setRobotIPContext(robotIP);
    }
	}

	const handleVerifyOtp = async(e) => {			
    setLoadingOTPcheck(true);
    setIsOtpError(false);
		e.preventDefault();
		let response;
		let url = 'https://' + robotIP + ':' + ROBOT_PORT_HTTP + '/api/otp/otpChecker';
		Logger(`send request for checking otp: ${url}`);		

		try {
			const otp = {
				otpNumber : otpNumber,
			}
			response = await axios({
				method: "post",
				url: url,
				headers: {
					"Cache-Control": "no-cache",
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				data: otp,
				responseType: "json",
			});
		} catch (error) {
      setLoadingOTPcheck(false);
      setIsVerifiedOtp(false);
      setOpenOTPcheckedMsg(false);
      setIsOtpError(true);
      Logger(`Error while checking ip address: ${error}`);
		}    
		
		Logger(`response from bric bridge: ${response.data.message}`);		
    setLoadingOTPcheck(false);
		if(response.data.message === "success") {
      setIsVerifiedOtp(true);
      setOpenOTPcheckedMsg(true);
      setIsOtpError(false);
    }
    else if(response.data.message === "fail"){
      setIsVerifiedOtp(false);
      setOpenOTPcheckedMsg(false);
      setIsOtpError(true);
    } 
	}	

	const handleDownloadCert = async(e) => {
    setLoadingDownloadCerts(true);
		e.preventDefault();
		let response;
		let url = 'https://' + robotIP + ':' + ROBOT_PORT_HTTP + '/download-cert';
		Logger(`request for downloading server certificate: ${url}`);

		try {
			response = await axios({
				method: "get",
				url: url,
				responseType: "blob",
        "Access-Control-Allow-Origin": "*",
			});
			if(IS_LOGGING_ON) console.log('response from bric bridge:', response);
      setLoadingDownloadCerts(false);
      setOpenDownloadCertsDoneMsg(true);
			const fileObjectUrl = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = fileObjectUrl;
			link.style.display = "none";
			link.setAttribute('download', 'emulserverss.crt');
			document.body.appendChild(link);
	    link.click();
  	  link.remove();
			window.URL.revokeObjectURL(fileObjectUrl);
		} catch (error) {
      setLoadingDownloadCerts(false);
      setOpenDownloadCertsDoneMsg(false);
			Logger(`Error while downloading server certificate: ${error}`);
		}        
	}

  useEffect(() => {    
    if(loadingIPcheck) {
      setButtonIPcheckText('Checking...');
    } else {
      if(isIPchecked) setButtonIPcheckText('DONE');
      else setButtonIPcheckText('Verity');
    }
  }, [loadingIPcheck])

  useEffect(() => {    
    if(isVerifiedOtp) {
      // Logger(`isVerifiedOtp: ${isVerifiedOtp}`);
      setCheckWssStatus(true);
    }
  }, [isVerifiedOtp])

  useEffect(() => {    
    if(loadingOTPcheck) {
      setButtonOTPcheckText('Checking...');
    } else {
      if(isVerifiedOtp) setButtonOTPcheckText('DONE');
      else setButtonOTPcheckText('Verity');
    }
  }, [loadingOTPcheck])

  useEffect(() => {    
    if(wssConnStatus !== 'closed') {
      if(wssConnStatus === 'open') setIsReadyContext(true);
      setCheckWssStatus(false);
    }
  }, [wssConnStatus])

  // useEffect(() => {
  //   Logger(`checkWssStatus: ${checkWssStatus}`);
  // }, [checkWssStatus])

  const handleCloseIPcheckedMsg = () => {
		setOpenIPcheckedMsg(false);
	}

  const handleCloseOTPcheckedMsg = () => {
    setOpenOTPcheckedMsg(false);
  }

  const handleCloseDownloadCertsDoneMsg = () => {
    setOpenDownloadCertsDoneMsg(false);
  }

  return (
		<>
		<RootStyle>
      <Grid
        container
        direction={isSmallScreen ? 'column' : 'row'}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ m:0, p:0 }}
      >        
        <Grid item xs={12} md={5.5} xl={5.5} container justifyContent="center" sx={{ my: 10 }}>
          <img
            alt="q9_intro"
            src={q9_intro}
            style={isSmallScreen ? 
              { width: '60%', height: 'auto', maxWidth: '30vh' } : 
              { width: '100%', height: 'auto', maxWidth: '50vh'}
            }
          />
        </Grid>
        <Grid item md={1} container justifyContent="center" sx={{ m:0, p:0 }}>
        </Grid>
        <Grid item xs={12} md={5.5} xl={5.5} container justifyContent="flex-start" sx={{ mt: isSmallScreen ? 0 : 10, mb: 10 }} >
          <Container
            disableGutters
            component="section"
            sx={{
              ml: 7,
              display: 'flex',            
              justifyContent: 'flex-start',
            }}
          >
            <Typography variant="h2" component="div" sx={{ color: 'text.info' }}>
              Set up connection with your Q9
            </Typography>
          </Container>
          <Stack direction='column'>
          <Box
            component="form"
            onSubmit={handleVerifyRobotIP}
            sx={{ ml: isSmallScreen ? 10 : 0, mt: 5 }}
          >
            <Typography variant="subtitle1" sx={{ color: 'text.info' }}>
              1. Browser settings (Chrome is recommended)
            </Typography>            
            <Typography variant="subtitle1" sx={{ mt: 1, px: 2, color: 'text.info' }}>
              1) Register server(robot) certificate for secure connection
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1, mr: 10, px: 5, color: 'text.info' }}>
              {` - To download the certificate from the robot, you need SDK installer. 
                   SDK installer can be downloaded from Q9 SDK Release page on the developer site.  `}
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1, px: 5, color: 'text.info' }}>
              {' - After downloading the certificate, register it in the browser.'}              
            </Typography>
            <Typography variant="subtitle2" sx={{ px: 6, color: 'text.info' }}>
              {'Settings -> Privacy and security -> Security -> Manage certificates -> Authorities -> Import'}
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, px: 2, color: 'text.info' }}>
              2) Allow private(local) network connections.
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1, mr: 10, px: 5, color: 'text.info' }}>
              {` - To connect to a robot with a private(local) IP from a website with a public IP, 
                   you need to allow the connection in your browser. Enter the URL below into your browser address bar and go to the page. `}
            </Typography>
            <Typography color='secondary' variant="subtitle2" sx={{ px: 5 }}>
              {'chrome://flags/#block-insecure-private-network-requests'}
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1, px: 5, color: 'text.info' }}>
              {` - Change the Block insecure private network requests to disabled. `}
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, px: 2, color: 'text.info' }}>
              3) Restart the browser.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3, color: 'text.info' }}>
              2. Robot settings
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 1, px: 2, color: 'text.info' }}>
              - Check if Wi-Fi is connected and launch BRIC app using SDK installer.
            </Typography>
            <Typography variant="caption" sx={{ mt: 1, px: 3, color: 'text.disabled', whiteSpace: 'pre-wrap' }}>
              {"* This device you are working on must be connected to the same local network as Q9.\n"}
            </Typography>
            <Typography variant="caption" sx={{ px: 3, color: 'text.disabled', whiteSpace: 'pre-wrap' }}>
              {"* You can see how to run the BRIC app in the SDK installer."}
            </Typography>
            <Typography variant="subtitle1" sx={{ my: 2, color: 'text.info' }}>
              3. Enter IP address displayed on your Q9 and click 'Verify'.
            </Typography>
            <FormGroup row sx={{ ml: 2 }}>
              <StyledTextField 
                required               
                color="secondary"
                variant="outlined"              
                label="ip address" 
                value={robotIP}
                error={isIpError}
                helperText={isIpError ? "Incorrect IP address" : ""}
                onChange={(e) => {
                  setRobotIP(e.target.value);
                }}
              />
              <LoadingButton
                variant="contained"
                loading={loadingIPcheck ? 1 : 0}
                disabled={loadingIPcheck || isIPchecked}
                type="submit"
                sx={{ ml: 1 }}
              >
                {loadingIPcheck && <Spinner size={24} />}
                {buttonIPcheckText}
              </LoadingButton>
            </FormGroup>   
          </Box>       

          <Box
            component="form"
            onSubmit={handleVerifyOtp}
            sx={{ ml: isSmallScreen ? 10 : 0, mt: 3 }}
          >
            <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.info' }}>
              4. Enter the number displayed on your Q9 and click 'Verify'.
            </Typography>
            <FormGroup row sx={{ ml: 2 }}>
              <StyledTextField 
                required               
                color="secondary"
                variant="outlined"              
                label="otp number" 
                error={isOtpError}
                helperText={isOtpError ? "Incorrect Otp" : ""}
                onChange={(e) => {
                  setOtpNumber(e.target.value);
                }}
              />
              <LoadingButton
                variant="contained"
                loading={loadingOTPcheck ? 1 : 0}
                disabled={!isIPchecked || loadingOTPcheck || isVerifiedOtp}
                type="submit"
                sx={{ ml: 1 }}
              >
              {loadingOTPcheck && <Spinner size={24} />}
              {buttonOTPcheckText}
            </LoadingButton>
            </FormGroup>   
          </Box>     
          
          {checkWssStatus &&            
            <WssConnChecker robotIP={robotIP} onCheckConnection={(connectionStatus) => {
              // Logger(`onCheckConnection - connectionStatus: ${connectionStatus}`);
              setWssConnStatus(connectionStatus);
            }}/>
          }
          {isVerifiedOtp &&
            wssConnStatus === 'error' &&
            <>
              <Box sx={{ ml: isSmallScreen ? 10 : 0, mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.info' }}>
                  4. For a secure connection, robot server certificate must be registered in the browser.
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 1, px: 2, color: 'text.info' }}>
                  1) Click the button below to download the certificate from your Q9.
                </Typography>
                <LoadingButton
                  variant="contained"
                  loading={loadingDownloadCerts ? 1 : 0}
                  onClick={handleDownloadCert}
                  sx={{ ml: 5, mt: 1, height: '40px' }}
                >
                  {loadingDownloadCerts && <Spinner size={24} />}
                  Download
                </LoadingButton>
                <Typography variant="subtitle1" sx={{ mt: 2, px: 2, color: 'text.info' }}>
                  2) Register the certificate in your browser.
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 1, pl: 5, color: 'text.info' }}>
                  {'Settings -> Privacy and security -> Security -> Manage certificates -> Authorities -> Import'}
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2, px: 2, color: 'text.info' }}>
                  3) Restart the browser.
                </Typography>
              </Box>
            </>
          }
          </Stack>
        </Grid>
      </Grid>      
    </RootStyle>
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openIPcheckedMsg}
      autoHideDuration={6000}
      onClose={handleCloseIPcheckedMsg}
    >
      <Alert
        onClose={handleCloseIPcheckedMsg}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Connection to Robot Checked Successfully
      </Alert>
    </Snackbar>
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openOTPcheckedMsg}
      autoHideDuration={6000}
      onClose={handleCloseOTPcheckedMsg}
    >
      <Alert
        onClose={handleCloseOTPcheckedMsg}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        OTP Checked Successfully
      </Alert>
    </Snackbar>
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openDownloadCertsDoneMsg}
      autoHideDuration={6000}
      onClose={handleCloseDownloadCertsDoneMsg}
    >
      <Alert
        onClose={handleCloseDownloadCertsDoneMsg}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Certificate Downloaded Successfully
      </Alert>
    </Snackbar>
		</>
  );
}