const apiInfo = {
  // Display
  c_interaction_facialExpression: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "int PlayDisplayRobotResources(DisplayEyeID DisplayEyeID, bool loop, bool instant_play);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_returnToNormalFace: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "int PlayDisplayRobotResources(DisplayEyeID DisplayEyeID, bool loop, bool instant_play);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_displayImage: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "int ShowImageAppResources(std::string fileName);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_displayText: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "void PlayDisplayText(std::string text_data);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_displayOnOff: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "void TurnOnandOffDisplay(bool control);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_setBrightness: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "void SetDisplayBrightness(int32_t brightness);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_updateToastMessage: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "void UpdateDisplayToastMessage(std::string toast);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_dismissToastMessage: {
    provider: "std::shared_ptr<homerobot_sdk::DisplayProvider> display_provider_;\n\n",
    function: "void DismissDisplayToastMessage();\n\n",
    callback: "// N/A\n",
  },
  // Sound
  c_interaction_playEffectSound_sync: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlaySoundRobotResources(SoundName_t resource);\n\n",
    callback: "void SoundPlaybackCallback(int sound_id);\n",
  },
  c_interaction_playEffectSound_async: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlaySoundRobotResources(SoundName_t resource);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_playSound_sync: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlaySoundAppResources(std::string fileName);\n\n",
    callback: "void SoundPlaybackCallback(int sound_id);\n",
  },
  c_interaction_playSound_async: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlaySoundAppResources(std::string fileName);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_playTts_sync: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlayTts(std::string text, int serverId);\n\n",
    callback: "void TTSCallback();\n",
  },
  c_interaction_playTts_async: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int PlayTts(std::string text, int serverId);\n\n",
    callback: "// N/A\n",
  },
  c_interaction_stopSound: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "// Need to use the stop function that matches the type of sound currently playing.\nint StopSoundRobotResources();\nint StopSound();\nint StopTts();\n\n",
    callback: "// N/A\n",
  },
  c_interaction_textFromSpeech: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int SttFromMicrophone(int serverId);\n\n",
    callback: "void STTCallback(std::string speech);\n",
  },
  c_sensing_startWakewordDetection: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int StartWakeword();\n\n",
    callback: "void WakeWordCallback(); // This API is mapped to the block, wakewordDetection\n",
  },
  c_sensing_wakeWordDetection: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int StartWakeword(); // This API is mapped to the block, StartWakewordDetection\n\n",
    callback: "void WakeWordCallback();\n",
  },
  c_sensing_stopWakewordDetection: {
    provider: "std::shared_ptr<homerobot_sdk::SoundsProvider> sounds_provider_;\n\n",
    function: "int StopWakeword();\n\n",
    callback: "// N/A\n",
  },
  // Setting
  c_interaction_volumeControl: {
    provider: "std::shared_ptr<homerobot_sdk::SettingProvider> setting_provider_;\n\n",
    function: "void SetSystemVolume(int volume);\n\n",
    callback: "// N/A\n",
  },
  // Motion
  c_motion_syncAsync: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int StartMotionOnce(MotionId motionID, MotionMove motionMove = MotionMove::ALL);\n\n",
    callbackSync: "void MotionFinishedCallback();\n",
    callbackAync: "// N/A\n",
  },
  c_motion_stopMotion: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int SafetyStopMotion();\n\n",
    callback: "// N/A\n",
  },
  c_movement_rotateHeadsetWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int RotateHeadset(float angle, float speed);\n\n",
    callback: "void HeadsetFinishedCallback();\n",
  },
  c_movement_rotateHeadsetWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int RotateHeadset(float angle, float speed);\n\n",
    callback: "// N/A\n",
  },
  c_movement_stopHeadset: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int StopHeadset();\n\n",
    callback: "// N/A\n",
  },
  c_movement_moveBothHipWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int MoveHip(float leftAngle, float rightAngle, float speed);\n\n",
    callback: "void HipFinishedCallback();\n",
  },
  c_movement_moveBothHipWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int MoveHip(float leftAngle, float rightAngle, float speed);\n\n",
    callback: "// N/A\n",
  },
  c_movement_moveOneHipWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int MoveLeftHip(float angle, float speed);\nint MoveRightHip(float angle, float speed);\n\n",
    callback: "void HipFinishedCallback();\n",
  },
  c_movement_moveOneHipWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int MoveLeftHip(float angle, float speed);\nint MoveRightHip(float angle, float speed);\n\n",
    callback: "// N/A\n",
  },
  c_movement_tiltBodyWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int BodyLeftRoll(int angle);\nint BodyRightRoll(int angle);\n\n",
    callback: "void HipFinishedCallback();\n",
  },
  c_movement_tiltBodyWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int BodyLeftRoll(int angle);\nint BodyRightRoll(int angle);\n\n",
    callback: "// N/A\n",
  },
  c_movement_stopHip: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int StopHip();\n\n",
    callback: "// N/A\n",
  },
  c_movement_initPose: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\n\n",
    function: "int RotateHeadset(float angle, float speed);\nint MoveHip(float leftAngle, float rightAngle, float speed);\n\n",
    callback: "// N/A\n",
  },
  // Navigation
  c_movement_spinDegreesWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status spinWithVelocity(const double & radian, const double & velocity, const double & time_allowance);\n\n",
    callback: "void SpinWithVelocityCallback(homerobot_sdk::Status status);\n",
  },
  c_movement_spinDegreesWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status spinWithVelocity(const double & radian, const double & velocity, const double & time_allowance);\n\n",
    callback: "// N/A\n",
  },
  c_movement_moveDistanceWithSpeed_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status driveOnHeading(const double & dist, const double & speed, const double & time_allowance);\nStatus backUp(const double & dist, const double & speed, const double & time_allowance);\n\n",
    callback: "void DriveOnHeadCallback(homerobot_sdk::Status status);\nvoid BackUpCallback(homerobot_sdk::Status status);\n",
  },
  c_movement_moveDistanceWithSpeed_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status driveOnHeading(const double & dist, const double & speed, const double & time_allowance);\nStatus backUp(const double & dist, const double & speed, const double & time_allowance);\n\n",
    callback: "// N/A\n",
  },
  c_movement_moveAlongTurningRadius_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status moveAlongTurnRadius(const double & linear_vel, const double & radian, const double & radius, const double & time_allowance);\n\n",
    callback: "void MoveAlongTurnRadiusCallback(homerobot_sdk::Status status);\n",
  },
  c_movement_moveAlongTurningRadius_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status moveAlongTurnRadius(const double & linear_vel, const double & radian, const double & radius, const double & time_allowance);\n\n",
    callback: "// N/A\n",
  },
  c_movement_stopWheel: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status cancelSpinWithVelocity(void);\nStatus cancelDriveOnHeading(void);\nStatus cancelBackUp(void);\nStatus cancelMoveAlongTurnRadius(void);\n\n",
    callback: "// N/A\n",
  },
  c_sensing_isDoingNaviTask: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "// This block is implemented based on navigation API functions and their callbacks.\n\n",
    callback: "// N/A\n",
  },
  c_navigation_prepare: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status prepareNavigation(void);\n\n",
    callback: "void PrepareNaviCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_createMap_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status createMap(void);\n\n",
    callback: "void CreateMapCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_createMap_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status createMap(void);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_goHome_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status returnToHome(void);\n\n",
    callback: "void ReturnToHomeCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_goHome_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status returnToHome(void);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_dockToCharger_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status dockToStation(void);\n\n",
    callback: "void DockToStationCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_dockToCharger_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status dockToStation(void);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_undockFromCharger_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status undockToStation(void);\n\n",
    callback: "void UndockFromStationCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_undockFromCharger_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status undockToStation(void);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_exploreNodes_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status exploreNodes(void);\n\n",
    callback: "void ExploreNodesCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_exploreNodes_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status exploreNodes(void);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_spinToFindPerson_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status spinToFindPerson(const int16_t & face_id);\n\n",
    callback: "void SpinToFindPersonCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_spinToFindPerson_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status spinToFindPerson(const int16_t & face_id);\n\n",
    callback: "// N/A\n",
  },  
  c_navigation_contactEyes_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status contactEyes(const int16_t & face_id);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_comeHere_sync: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status comeHere(const int16_t & face_id);\n\n",
    callback: "void ComeHereCallback(homerobot_sdk::Status status);\n",
  },
  c_navigation_comeHere_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status comeHere(const int16_t & face_id);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_followMe_async: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status followMe(const int16_t & face_id);\n\n",
    callback: "// N/A\n",
  },
  c_navigation_cancelNaviTask: {
    provider: "std::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "Status cancelNavigationManager(void);\n\n",
    callback: "// N/A\n",
  },
  // Motion + Navi
  c_movement_stopAllMovement: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\nstd::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "int SafetyStopMotion();\nint StopHeadset();\nint StopHip();\nStatus cancelSpinWithVelocity(void);\nStatus cancelDriveOnHeading(void);\nStatus cancelBackUp(void);\nStatus cancelMoveAlongTurnRadius(void);\n\n",
    callback: "// N/A\n",
  },
  c_sensing_allMovementStopped: {
    provider: "std::shared_ptr<homerobot_sdk::MotionProvider> motion_provider_;\nstd::shared_ptr<homerobot_sdk::NavigationProvider> navigation_provider_;\n\n",
    function: "// This block is implemented based on motion and navigation API functions and their callbacks.\n\n",
    callback: "// N/A\n",
  },
  // Recognition
  c_sensing_personDetection: {
    provider: "std::shared_ptr<homerobot_sdk::RecognitionProvider> recognition_provider_;\n\n",
    function: "int DetectHuman();\n\n",
    callback: "void HumanDetectCallback(recognition_msgs::msg::PersonInfoArray::SharedPtr msg);\n",
  },
  c_sensing_faceDetection: {
    provider: "std::shared_ptr<homerobot_sdk::RecognitionProvider> recognition_provider_;\n\n",
    function: "int CheckFace();\n\n",
    callback: "void FaceDetectCallback(recognition_msgs::msg::FaceInfoArray::SharedPtr msg);\n",
  },
  c_sensing_numberOfFaceDetected: {
    provider: "std::shared_ptr<homerobot_sdk::RecognitionProvider> recognition_provider_;\n\n",
    function: "int CheckFace();\n\n",
    callback: "void FaceDetectCallback(recognition_msgs::msg::FaceInfoArray::SharedPtr msg);\n",
  },
  // Sensor
  c_sensing_headsetAngle: {
    provider: "std::shared_ptr<homerobot_sdk::SensorProvider> sensor_provider_;\n\n",
    function: "int GetCurrentHeadset();\n\n",
    callback: "// N/A\n",
  },
  c_sensing_hipAngle: {
    provider: "std::shared_ptr<homerobot_sdk::SensorProvider> sensor_provider_;\n\n",
    function: "int GetCurrentLeftHip();\nint GetCurrentRightHip();\n\n",
    callback: "// N/A\n",
  },
  c_sensing_wheelSpeed: {
    provider: "std::shared_ptr<homerobot_sdk::SensorProvider> sensor_provider_;\n\n",
    function: "int GetCurrentLeftWheel();\nint GetCurrentRightWheel();\n\n",
    callback: "// N/A\n",
  },
  c_sensing_batteryLevel: {
    provider: "std::shared_ptr<homerobot_sdk::SensorProvider> sensor_provider_;\n\n",
    function: "int GetBatteryRemain();\n\n",
    callback: "// N/A\n",
  },  
};

export default apiInfo;
