import { javascriptGenerator, Order } from "blockly/javascript";

javascriptGenerator.forBlock['c_sensing_startWakewordDetection'] = function(block, generator) {	
	const code = `startWakewordDetection();\n`;
	return code;
};

javascriptGenerator.forBlock['c_sensing_wakeWordDetection'] = function(block, generator) {		
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `checkIfWakewordDetected(highlightBlock('${block.id}', false),updateRecentValue())` 
	: code = `checkIfWakewordDetected(updateRecentValue())`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_stopWakewordDetection'] = function(block, generator) {	
	const code = `stopWakewordDetection();\n`;
	return code;
};

// javascriptGenerator.forBlock['c_sensing_startPersonDetection'] = function(block, generator) {	
// 	const code = `startPersonDetection();\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_sensing_personDetection'] = function(block, generator) {
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `checkIfPersonDetected(highlightBlock('${block.id}', false), startPersonDetection(), personDetectionFinishChecker())` 
	: code = `checkIfPersonDetected(startPersonDetection(), personDetectionFinishChecker())`;
  return [code, Order.NONE];
};

// javascriptGenerator.forBlock['c_sensing_stopPersonDetection'] = function(block, generator) {	
// 	const code = `stopPersonDetection();\n`;
// 	return code;
// };

javascriptGenerator.forBlock['c_sensing_faceDetection'] = function(block, generator) {
	const userIdx = Number(block.getFieldValue('USERLIST'));
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getDetectedFaceList(${userIdx}, highlightBlock('${block.id}', false), startFaceDetection(), faceDetectionFinishChecker())` 
	: code = `getDetectedFaceList(${userIdx}, startFaceDetection(), faceDetectionFinishChecker())`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_numberOfFaceDetected'] = function(block, generator) {	
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getNumberOfFaceDetected(highlightBlock('${block.id}', false), startFaceDetection(), faceDetectionFinishChecker())` 
	: code = `getNumberOfFaceDetected(startFaceDetection(), faceDetectionFinishChecker())` 	
  return [code, Order.NONE];
};

// javascriptGenerator.forBlock['c_sensing_fallDownDetection'] = function(block, generator) {
// 	const elementId = 'isFalldown';	
// 	const code = `getFalldownStatus(startFallDownDetection(), sensingFinishChecker('${elementId}'))`;	
//   return [code, Order.NONE];
// };

// javascriptGenerator.forBlock['c_sensing_pickUpDetection'] = function(block, generator) {
// 	const elementId = 'isPickUp';	
// 	const code = `getPickupStatus(startPickupDetection(), sensingFinishChecker('${elementId}'))`;
//   return [code, Order.NONE];
// };

javascriptGenerator.forBlock['c_sensing_headsetAngle'] = function(block, generator) {	
	const elementId = 'angleOfHeadset';	
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getAngleOfHeadset(highlightBlock('${block.id}', false), startGetHeadsetAngle(), getValueFinishChecker('${elementId}'))` 
	: code = `getAngleOfHeadset(startGetHeadsetAngle(), getValueFinishChecker('${elementId}'))` 	
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_hipAngle'] = function(block, generator) {
	const direction = block.getFieldValue('DIRECTION');
	const elementId = 'angleOfHip';
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getAngleOfHip('${direction}', highlightBlock('${block.id}', false), startGetHipAngle(), getValueFinishChecker('${elementId}'))`
	: code = `getAngleOfHip('${direction}', startGetHipAngle(), getValueFinishChecker('${elementId}'))`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_wheelSpeed'] = function(block, generator) {
	const direction = block.getFieldValue('DIRECTION');
	const elementId = 'speedOfWheel';		
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getSpeedOfWheel('${direction}', highlightBlock('${block.id}', false), startGetWheelSpeed(), getValueFinishChecker('${elementId}'))`
	: code = `getSpeedOfWheel('${direction}', startGetWheelSpeed(), getValueFinishChecker('${elementId}'))`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_batteryLevel'] = function(block, generator) {
	const elementId = 'batteryLevel';		
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `getBatteryLevel(highlightBlock('${block.id}', false), startGetBatteryInfo(),getValueFinishChecker('${elementId}'))`
	: code = `getBatteryLevel(startGetBatteryInfo(),getValueFinishChecker('${elementId}'))`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_allMovementStopped'] = function(block, generator) {		
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `checkIfAllMovementStopped(highlightBlock('${block.id}', false),updateRecentValue())` 
	: code = `checkIfAllMovementStopped(updateRecentValue())`;
  return [code, Order.NONE];
};

javascriptGenerator.forBlock['c_sensing_isDoingNaviTask'] = function(block, generator) {		
	const withStepUI = block.workspace.getBlocksByType('c_program_start', false)[0].getFieldValue('STEP') === 'TRUE';
	let code;
	withStepUI ? 
	code = `checkIfIsDoingNaviTask(highlightBlock('${block.id}', false),updateRecentValue())` 
	: code = `checkIfIsDoingNaviTask(updateRecentValue())`;
  return [code, Order.NONE];
};