import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// theme
import ThemeProvider from './theme';
// pages
import Home from './pages/Home';
import Workspace from './pages/Workspace';
import NotFound from './pages/NotFound';
// layout
import MainLayout from './layouts/MainLayout';
import WorkspaceLayout from './layouts/WorkspaceLayout';
// context
import { ConnectContext } from './contexts/ConnectContext';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider>
		<ConnectContext>
			<BrowserRouter>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path="/" element={<Home />} />
					<Route path="*" element={<NotFound />}/>
				</Route>
				<Route element={<WorkspaceLayout />}>
					<Route path="/Workspace" element={<Workspace />} />
				</Route>
			</Routes>
			</BrowserRouter>   
		</ConnectContext>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
