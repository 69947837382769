import Blockly from 'blockly/core';
import { motionList } from './motionList';

// block type naming rule : {toolbox_name}_{block_description}

const motionJson = {
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'MOTION',
			'options': [			
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "motion_blocks",
}

const motionSyncJson = JSON.parse(JSON.stringify(motionJson));
motionSyncJson.message0 = "start motion %1 until end";
const motionAsyncJson = JSON.parse(JSON.stringify(motionJson));
motionAsyncJson.message0 = "start motion %1";

const motionAweOptions = [
	['AWE_1', motionList.indexOf('E1_AWE_1').toString()],
	// ['AWE_2', motionList.indexOf('E1_AWE_2').toString()],
];

const motionAweSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionAweSyncJson.args0[0].options = motionAweOptions;
Blockly.Blocks['c_motion_awe_sync'] = {
	init: function() {
		this.jsonInit(motionAweSyncJson);
	}
}

const motionAweAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionAweAsyncJson.args0[0].options = motionAweOptions;
Blockly.Blocks['c_motion_awe_async'] = {
	init: function() {
		this.jsonInit(motionAweAsyncJson);
	}
}

const motionExcitingOptions = [
	['EXCITING_1', motionList.indexOf('E2_EXCITING_1').toString()],
	['EXCITING_2', motionList.indexOf('E2_EXCITING_2').toString()],
];

const motionExcitingSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionExcitingSyncJson.args0[0].options = motionExcitingOptions;
Blockly.Blocks['c_motion_exciting_sync'] = {
	init: function() {
		this.jsonInit(motionExcitingSyncJson);
	}
}

const motionExcitingAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionExcitingAsyncJson.args0[0].options = motionExcitingOptions;
Blockly.Blocks['c_motion_exciting_async'] = {
	init: function() {
		this.jsonInit(motionExcitingAsyncJson);
	}
}

const motionSurpriseOptions = [
	['SURPRISE_1', motionList.indexOf('E3_SURPRISE_1').toString()],
];

const motionSurpriseSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionSurpriseSyncJson.args0[0].options = motionSurpriseOptions;
Blockly.Blocks['c_motion_surprise_sync'] = {
	init: function() {
		this.jsonInit(motionSurpriseSyncJson);
	}
}

const motionSurpriseAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionSurpriseAsyncJson.args0[0].options = motionSurpriseOptions;
Blockly.Blocks['c_motion_surprise_async'] = {
	init: function() {
		this.jsonInit(motionSurpriseAsyncJson);
	}
}

const motionAnticipationOptions = [
	['ANTICIPATION_1', motionList.indexOf('E4_ANTICIPATION_1').toString()],
	['ANTICIPATION_2', motionList.indexOf('E4_ANTICIPATION_2').toString()],
	['ANTICIPATION_3', motionList.indexOf('E4_ANTICIPATION_3').toString()],
];

const motionAnticipationSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionAnticipationSyncJson.args0[0].options = motionAnticipationOptions;
Blockly.Blocks['c_motion_anticipation_sync'] = {
	init: function() {
		this.jsonInit(motionAnticipationSyncJson);
	}
}

const motionAnticipationAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionAnticipationAsyncJson.args0[0].options = motionAnticipationOptions;
Blockly.Blocks['c_motion_anticipation_async'] = {
	init: function() {
		this.jsonInit(motionAnticipationAsyncJson);
	}
}

const motionConfidentOptions = [
	['CONFIDENT_1', motionList.indexOf('E5_CONFIDENT_1').toString()],
	['CONFIDENT_2', motionList.indexOf('E5_CONFIDENT_2').toString()],
];

const motionConfidentSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionConfidentSyncJson.args0[0].options = motionConfidentOptions;
Blockly.Blocks['c_motion_confident_sync'] = {
	init: function() {
		this.jsonInit(motionConfidentSyncJson);
	}
}

const motionConfidentAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionConfidentAsyncJson.args0[0].options = motionConfidentOptions;
Blockly.Blocks['c_motion_confident_async'] = {
	init: function() {
		this.jsonInit(motionConfidentAsyncJson);
	}
}

const motionLoveOptions = [
	['LOVE_1', motionList.indexOf('E6_LOVE_1').toString()],
];

const motionLoveSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionLoveSyncJson.args0[0].options = motionLoveOptions;
Blockly.Blocks['c_motion_love_sync'] = {
	init: function() {
		this.jsonInit(motionLoveSyncJson);
	}
}

const motionLoveAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionLoveAsyncJson.args0[0].options = motionLoveOptions;
Blockly.Blocks['c_motion_love_async'] = {
	init: function() {
		this.jsonInit(motionLoveAsyncJson);
	}
}

const motionDelightOptions = [
	['DELIGHT_1', motionList.indexOf('E7_DELIGHT_1').toString()],
	['DELIGHT_2', motionList.indexOf('E7_DELIGHT_2').toString()],
];

const motionDelightSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionDelightSyncJson.args0[0].options = motionDelightOptions;
Blockly.Blocks['c_motion_delight_sync'] = {
	init: function() {
		this.jsonInit(motionDelightSyncJson);
	}
}

const motionDelightAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionDelightAsyncJson.args0[0].options = motionDelightOptions;
Blockly.Blocks['c_motion_delight_async'] = {
	init: function() {
		this.jsonInit(motionDelightAsyncJson);
	}
}

const motionJoyOptions = [
	['JOY_1', motionList.indexOf('E8_JOY_1').toString()],
	['JOY_2', motionList.indexOf('E8_JOY_2').toString()],
];

const motionJoySyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionJoySyncJson.args0[0].options = motionJoyOptions;
Blockly.Blocks['c_motion_joy_sync'] = {
	init: function() {
		this.jsonInit(motionJoySyncJson);
	}
}

const motionJoyAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionJoyAsyncJson.args0[0].options = motionJoyOptions;
Blockly.Blocks['c_motion_joy_async'] = {
	init: function() {
		this.jsonInit(motionJoyAsyncJson);
	}
}

const motionCuriosityOptions = [
	['CURIOSITY_1', motionList.indexOf('E9_CURIOSITY_1').toString()],
];

const motionCuriositySyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionCuriositySyncJson.args0[0].options = motionCuriosityOptions;
Blockly.Blocks['c_motion_curiosity_sync'] = {
	init: function() {
		this.jsonInit(motionCuriositySyncJson);
	}
}

const motionCuriosityAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionCuriosityAsyncJson.args0[0].options = motionCuriosityOptions;
Blockly.Blocks['c_motion_curiosity_async'] = {
	init: function() {
		this.jsonInit(motionCuriosityAsyncJson);
	}
}

const motionRelievedOptions = [
	['RELIEVED_1', motionList.indexOf('E12_RELIEVED_1').toString()],
];

const motionRelievedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionRelievedSyncJson.args0[0].options = motionRelievedOptions;
Blockly.Blocks['c_motion_relieved_sync'] = {
	init: function() {
		this.jsonInit(motionRelievedSyncJson);
	}
}

const motionRelievedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionRelievedAsyncJson.args0[0].options = motionRelievedOptions;
Blockly.Blocks['c_motion_relieved_async'] = {
	init: function() {
		this.jsonInit(motionRelievedAsyncJson);
	}
}

const motionSatisfiedOptions = [
	['SATISFIED_1', motionList.indexOf('E13_SATISFIED_1').toString()],
];

const motionSatisfiedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionSatisfiedSyncJson.args0[0].options = motionSatisfiedOptions;
Blockly.Blocks['c_motion_satisfied_sync'] = {
	init: function() {
		this.jsonInit(motionSatisfiedSyncJson);
	}
}

const motionSatisfiedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionSatisfiedAsyncJson.args0[0].options = motionSatisfiedOptions;
Blockly.Blocks['c_motion_satisfied_async'] = {
	init: function() {
		this.jsonInit(motionSatisfiedAsyncJson);
	}
}

const motionTouchedOptions = [
	['TOUCHED_A_1', motionList.indexOf('E14_TOUCHED_A_1').toString()],
	['TOUCHED_B_1', motionList.indexOf('E14_TOUCHED_B_1').toString()],
];

const motionTouchedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionTouchedSyncJson.args0[0].options = motionTouchedOptions;
Blockly.Blocks['c_motion_touched_sync'] = {
	init: function() {
		this.jsonInit(motionTouchedSyncJson);
	}
}

const motionTouchedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionTouchedAsyncJson.args0[0].options = motionTouchedOptions;
Blockly.Blocks['c_motion_touched_async'] = {
	init: function() {
		this.jsonInit(motionTouchedAsyncJson);
	}
}

const motionAgreeOptions = [
	['AGREE_1', motionList.indexOf('E15_AGREE_1').toString()],
];

const motionAgreeSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionAgreeSyncJson.args0[0].options = motionAgreeOptions;
Blockly.Blocks['c_motion_agree_sync'] = {
	init: function() {
		this.jsonInit(motionAgreeSyncJson);
	}
}

const motionAgreeAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionAgreeAsyncJson.args0[0].options = motionAgreeOptions;
Blockly.Blocks['c_motion_agree_async'] = {
	init: function() {
		this.jsonInit(motionAgreeAsyncJson);
	}
}

const motionShynessOptions = [
	['SHYNESS_A_1', motionList.indexOf('E16_SHYNESS_A_1').toString()],
	['SHYNESS_B_1', motionList.indexOf('E16_SHYNESS_B_1').toString()],
];

const motionShynessSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionShynessSyncJson.args0[0].options = motionShynessOptions;
Blockly.Blocks['c_motion_shyness_sync'] = {
	init: function() {
		this.jsonInit(motionShynessSyncJson);
	}
}

const motionShynessAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionShynessAsyncJson.args0[0].options = motionShynessOptions;
Blockly.Blocks['c_motion_shyness_async'] = {
	init: function() {
		this.jsonInit(motionShynessAsyncJson);
	}
}

const motionProudOptions = [
	['PROUD_1', motionList.indexOf('E17_PROUD_1').toString()],
];

const motionProudSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionProudSyncJson.args0[0].options = motionProudOptions;
Blockly.Blocks['c_motion_proud_sync'] = {
	init: function() {
		this.jsonInit(motionProudSyncJson);
	}
}

const motionProudAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionProudAsyncJson.args0[0].options = motionProudOptions;
Blockly.Blocks['c_motion_proud_async'] = {
	init: function() {
		this.jsonInit(motionProudAsyncJson);
	}
}

const motionInterestedOptions = [
	['INTERESTED_1', motionList.indexOf('E18_INTERESTED_1').toString()],
];

const motionInterestedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionInterestedSyncJson.args0[0].options = motionInterestedOptions;
Blockly.Blocks['c_motion_interested_sync'] = {
	init: function() {
		this.jsonInit(motionInterestedSyncJson);
	}
}

const motionInterestedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionInterestedAsyncJson.args0[0].options = motionInterestedOptions;
Blockly.Blocks['c_motion_interested_async'] = {
	init: function() {
		this.jsonInit(motionInterestedAsyncJson);
	}
}

const motionPlayfulOptions = [
	['PLAYFUL_1', motionList.indexOf('E19_PLAYFUL_1').toString()],
];

const motionPlayfulSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionPlayfulSyncJson.args0[0].options = motionPlayfulOptions;
Blockly.Blocks['c_motion_playful_sync'] = {
	init: function() {
		this.jsonInit(motionPlayfulSyncJson);
	}
}

const motionPlayfulAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionPlayfulAsyncJson.args0[0].options = motionPlayfulOptions;
Blockly.Blocks['c_motion_playful_async'] = {
	init: function() {
		this.jsonInit(motionPlayfulAsyncJson);
	}
}

const motionTrembleOptions = [
	['TREMBLE_1', motionList.indexOf('E20_TREMBLE_1').toString()],
];

const motionTrembleSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionTrembleSyncJson.args0[0].options = motionTrembleOptions;
Blockly.Blocks['c_motion_tremble_sync'] = {
	init: function() {
		this.jsonInit(motionTrembleSyncJson);
	}
}

const motionTrembleAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionTrembleAsyncJson.args0[0].options = motionTrembleOptions;
Blockly.Blocks['c_motion_tremble_async'] = {
	init: function() {
		this.jsonInit(motionTrembleAsyncJson);
	}
}

const motionThrilledOptions = [
	['THRILLED_1', motionList.indexOf('E21_THRILLED_1').toString()],
];

const motionThrilledSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionThrilledSyncJson.args0[0].options = motionThrilledOptions;
Blockly.Blocks['c_motion_thrilled_sync'] = {
	init: function() {
		this.jsonInit(motionThrilledSyncJson);
	}
}

const motionThrilledAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionThrilledAsyncJson.args0[0].options = motionThrilledOptions;
Blockly.Blocks['c_motion_thrilled_async'] = {
	init: function() {
		this.jsonInit(motionThrilledAsyncJson);
	}
}

const motionThinkingOptions = [
	['THINKING_A_1', motionList.indexOf('E22_THINKING_A_1').toString()],
	['THINKING_B_1', motionList.indexOf('E22_THINKING_B_1').toString()],
];

const motionThinkingSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionThinkingSyncJson.args0[0].options = motionThinkingOptions;
Blockly.Blocks['c_motion_thinking_sync'] = {
	init: function() {
		this.jsonInit(motionThinkingSyncJson);
	}
}

const motionThinkingAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionThinkingAsyncJson.args0[0].options = motionThinkingOptions;
Blockly.Blocks['c_motion_thinking_async'] = {
	init: function() {
		this.jsonInit(motionThinkingAsyncJson);
	}
}

const motionDizzyOptions = [
	['DIZZY_1', motionList.indexOf('E51_DIZZY_1').toString()],		
];

const motionDizzySyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionDizzySyncJson.args0[0].options = motionDizzyOptions;
Blockly.Blocks['c_motion_dizzy_sync'] = {
	init: function() {
		this.jsonInit(motionDizzySyncJson);
	}
}

const motionDizzyAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionDizzyAsyncJson.args0[0].options = motionDizzyOptions;
Blockly.Blocks['c_motion_dizzy_async'] = {
	init: function() {
		this.jsonInit(motionDizzyAsyncJson);
	}
}

const motionSadnessOptions = [
	['SADNESS_1', motionList.indexOf('E52_SADNESS_1').toString()],		
];

const motionSadnessSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionSadnessSyncJson.args0[0].options = motionSadnessOptions;
Blockly.Blocks['c_motion_sadness_sync'] = {
	init: function() {
		this.jsonInit(motionSadnessSyncJson);
	}
}

const motionSadnessAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionSadnessAsyncJson.args0[0].options = motionSadnessOptions;
Blockly.Blocks['c_motion_sadness_async'] = {
	init: function() {
		this.jsonInit(motionSadnessAsyncJson);
	}
}

const motionShameOptions = [
	['SHAME_1', motionList.indexOf('E53_SHAME_1').toString()],				
];

const motionShameSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionShameSyncJson.args0[0].options = motionShameOptions;
Blockly.Blocks['c_motion_shame_sync'] = {
	init: function() {
		this.jsonInit(motionShameSyncJson);
	}
}

const motionShameAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionShameAsyncJson.args0[0].options = motionShameOptions;
Blockly.Blocks['c_motion_shame_async'] = {
	init: function() {
		this.jsonInit(motionShameAsyncJson);
	}
}

const motionAnxietyOptions = [
	['ANXIETY_1', motionList.indexOf('E54_ANXIETY_1').toString()],
	['ANXIETY_2', motionList.indexOf('E54_ANXIETY_2').toString()],
];

const motionAnxietySyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
motionAnxietySyncJson.args0[0].options = motionAnxietyOptions;
Blockly.Blocks['c_motion_anxiety_sync'] = {
	init: function() {
		this.jsonInit(motionAnxietySyncJson);
	}
}

const motionAnxietyAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
motionAnxietyAsyncJson.args0[0].options = motionAnxietyOptions;
Blockly.Blocks['c_motion_anxiety_async'] = {
	init: function() {
		this.jsonInit(motionAnxietyAsyncJson);
	}
}

const motionFearOptions = [
	['FEAR_1', motionList.indexOf('E55_FEAR_1').toString()],
];

const motionFearSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionFearSyncJson.args0[0].options = motionFearOptions;
Blockly.Blocks['c_motion_fear_sync'] = {
	init: function() {
		this.jsonInit(motionFearSyncJson);
	}
}

const motionFearAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionFearAsyncJson.args0[0].options = motionFearOptions;
Blockly.Blocks['c_motion_fear_async'] = {
	init: function() {
		this.jsonInit(motionFearAsyncJson);
	}
}

const motionAshamedOptions = [
	['ASHAMED_1', motionList.indexOf('E56_ASHAMED_1').toString()],
];

const motionAshamedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionAshamedSyncJson.args0[0].options = motionAshamedOptions;
Blockly.Blocks['c_motion_ashamed_sync'] = {
	init: function() {
		this.jsonInit(motionAshamedSyncJson);
	}
}

const motionAshamedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionAshamedAsyncJson.args0[0].options = motionAshamedOptions;
Blockly.Blocks['c_motion_ashamed_async'] = {
	init: function() {
		this.jsonInit(motionAshamedAsyncJson);
	}
}

const motionShockedOptions = [
	['SHOCKED_1', motionList.indexOf('E57_SHOCKED_1').toString()],
];

const motionShockedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionShockedSyncJson.args0[0].options = motionShockedOptions;
Blockly.Blocks['c_motion_shocked_sync'] = {
	init: function() {
		this.jsonInit(motionShockedSyncJson);
	}
}

const motionShockedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionShockedAsyncJson.args0[0].options = motionShockedOptions;
Blockly.Blocks['c_motion_shocked_async'] = {
	init: function() {
		this.jsonInit(motionShockedAsyncJson);
	}
}

const motionBoredOptions = [
	['BORED_1', motionList.indexOf('E58_BORED_1').toString()],
];

const motionBoredSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionBoredSyncJson.args0[0].options = motionBoredOptions;
Blockly.Blocks['c_motion_bored_sync'] = {
	init: function() {
		this.jsonInit(motionBoredSyncJson);
	}
}

const motionBoredAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionBoredAsyncJson.args0[0].options = motionBoredOptions;
Blockly.Blocks['c_motion_bored_async'] = {
	init: function() {
		this.jsonInit(motionBoredAsyncJson);
	}
}

const motionTiredOptions = [
	['TIRED_1', motionList.indexOf('E59_TIRED_1').toString()],
];

const motionTiredSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionTiredSyncJson.args0[0].options = motionTiredOptions;
Blockly.Blocks['c_motion_tired_sync'] = {
	init: function() {
		this.jsonInit(motionTiredSyncJson);
	}
}

const motionTiredAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionTiredAsyncJson.args0[0].options = motionTiredOptions;
Blockly.Blocks['c_motion_tired_async'] = {
	init: function() {
		this.jsonInit(motionTiredAsyncJson);
	}
}

const motionConfusedOptions = [
	['CONFUSED_1', motionList.indexOf('E60_CONFUSED_1').toString()],
];

const motionConfusedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionConfusedSyncJson.args0[0].options = motionConfusedOptions;
Blockly.Blocks['c_motion_confused_sync'] = {
	init: function() {
		this.jsonInit(motionConfusedSyncJson);
	}
}

const motionConfusedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionConfusedAsyncJson.args0[0].options = motionConfusedOptions;
Blockly.Blocks['c_motion_confused_async'] = {
	init: function() {
		this.jsonInit(motionConfusedAsyncJson);
	}
}

const motionEmbarrassedOptions = [
	['EMBARRASSED_1', motionList.indexOf('E61_EMBARRASSED_1').toString()],
];

const motionEmbarrassedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionEmbarrassedSyncJson.args0[0].options = motionEmbarrassedOptions;
Blockly.Blocks['c_motion_embarrassed_sync'] = {
	init: function() {
		this.jsonInit(motionEmbarrassedSyncJson);
	}
}

const motionEmbarrassedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionEmbarrassedAsyncJson.args0[0].options = motionEmbarrassedOptions;
Blockly.Blocks['c_motion_embarrassed_async'] = {
	init: function() {
		this.jsonInit(motionEmbarrassedAsyncJson);
	}
}

const motionJealousOptions = [
	['JEALOUS_1', motionList.indexOf('E62_JEALOUS_1').toString()],
];

const motionJealousSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionJealousSyncJson.args0[0].options = motionJealousOptions;
Blockly.Blocks['c_motion_jealous_sync'] = {
	init: function() {
		this.jsonInit(motionJealousSyncJson);
	}
}

const motionJealousAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionJealousAsyncJson.args0[0].options = motionJealousOptions;
Blockly.Blocks['c_motion_jealous_async'] = {
	init: function() {
		this.jsonInit(motionJealousAsyncJson);
	}
}

const motionSuspiciousOptions = [
	['SUSPICIOUS_1', motionList.indexOf('E63_SUSPICIOUS_1').toString()],
];

const motionSuspiciousSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionSuspiciousSyncJson.args0[0].options = motionSuspiciousOptions;
Blockly.Blocks['c_motion_suspicious_sync'] = {
	init: function() {
		this.jsonInit(motionSuspiciousSyncJson);
	}
}

const motionSuspiciousAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionSuspiciousAsyncJson.args0[0].options = motionSuspiciousOptions;
Blockly.Blocks['c_motion_suspicious_async'] = {
	init: function() {
		this.jsonInit(motionSuspiciousAsyncJson);
	}
}

const motionMadOptions = [
	['MAD_1', motionList.indexOf('E64_MAD_1').toString()],
];

const motionMadSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionMadSyncJson.args0[0].options = motionMadOptions;
Blockly.Blocks['c_motion_mad_sync'] = {
	init: function() {
		this.jsonInit(motionMadSyncJson);
	}
}

const motionMadAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionMadAsyncJson.args0[0].options = motionMadOptions;
Blockly.Blocks['c_motion_mad_async'] = {
	init: function() {
		this.jsonInit(motionMadAsyncJson);
	}
}

const motionUnsatisfiedOptions = [
	['UNSATISFIED_1', motionList.indexOf('E65_UNSATISFIED_1').toString()],
];

const motionUnsatisfiedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionUnsatisfiedSyncJson.args0[0].options = motionUnsatisfiedOptions;
Blockly.Blocks['c_motion_unsatisfied_sync'] = {
	init: function() {
		this.jsonInit(motionUnsatisfiedSyncJson);
	}
}

const motionUnsatisfiedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionUnsatisfiedAsyncJson.args0[0].options = motionUnsatisfiedOptions;
Blockly.Blocks['c_motion_unsatisfied_async'] = {
	init: function() {
		this.jsonInit(motionUnsatisfiedAsyncJson);
	}
}

const motionAnnoyedOptions = [
	['ANNOYED_1', motionList.indexOf('E66_ANNOYED_1').toString()],
];

const motionAnnoyedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionAnnoyedSyncJson.args0[0].options = motionAnnoyedOptions;
Blockly.Blocks['c_motion_annoyed_sync'] = {
	init: function() {
		this.jsonInit(motionAnnoyedSyncJson);
	}
}

const motionAnnoyedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionAnnoyedAsyncJson.args0[0].options = motionAnnoyedOptions;
Blockly.Blocks['c_motion_annoyed_async'] = {
	init: function() {
		this.jsonInit(motionAnnoyedAsyncJson);
	}
}

const motionConversationAnalyzingOptions = [
	['CONVERSATION_ANALYZING_1', motionList.indexOf('F0_CONVERSATION_ANALYZING_1').toString()],
	['CONVERSATION_ANALYZING_2', motionList.indexOf('F0_CONVERSATION_ANALYZING_2').toString()],
	['CONVERSATION_ANALYZING_3', motionList.indexOf('F0_CONVERSATION_ANALYZING_3').toString()],
];

const motionConversationAnalyzingSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionConversationAnalyzingSyncJson.args0[0].options = motionConversationAnalyzingOptions;
Blockly.Blocks['c_motion_conversationAnalyzing_sync'] = {
	init: function() {
		this.jsonInit(motionConversationAnalyzingSyncJson);
	}}

const motionConversationAnalyzingAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionConversationAnalyzingAsyncJson.args0[0].options = motionConversationAnalyzingOptions;
Blockly.Blocks['c_motion_conversationAnalyzing_async'] = {
	init: function() {
		this.jsonInit(motionConversationAnalyzingAsyncJson);
	}
}

const motionConversationFailedOptions = [
	['CONVERSATION_FAILED_1', motionList.indexOf('F0_CONVERSATION_FAILED_1').toString()],
];

const motionConversationFailedSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionConversationFailedSyncJson.args0[0].options = motionConversationFailedOptions;
Blockly.Blocks['c_motion_conversationFailed_sync'] = {
	init: function() {
		this.jsonInit(motionConversationFailedSyncJson);
	}}

const motionConversationFailedAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionConversationFailedAsyncJson.args0[0].options = motionConversationFailedOptions;
Blockly.Blocks['c_motion_conversationFailed_async'] = {
	init: function() {
		this.jsonInit(motionConversationFailedAsyncJson);
	}
}

const motionLookAroundOptions = [
	['LOOK_AROUND_1', motionList.indexOf('D1_LOOK_AROUND_1').toString()],
];

const motionLookAroundSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionLookAroundSyncJson.args0[0].options = motionLookAroundOptions;
Blockly.Blocks['c_motion_lookAround_sync'] = {
	init: function() {
		this.jsonInit(motionLookAroundSyncJson);
	}
}

const motionLookAroundAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionLookAroundAsyncJson.args0[0].options = motionLookAroundOptions;
Blockly.Blocks['c_motion_lookAround_async'] = {
	init: function() {
		this.jsonInit(motionLookAroundAsyncJson);
	}
}

const motionShakeHeadsetOptions = [
	['SHAKE_HEADSET_1', motionList.indexOf('A1_SHAKE_HEADSET_1').toString()],
];

const motionShakeHeadsetSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionShakeHeadsetSyncJson.args0[0].options = motionShakeHeadsetOptions;
Blockly.Blocks['c_motion_shakeHeadset_sync'] = {
	init: function() {
		this.jsonInit(motionShakeHeadsetSyncJson);
	}
}

const motionShakeHeadsetAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionShakeHeadsetAsyncJson.args0[0].options = motionShakeHeadsetOptions;
Blockly.Blocks['c_motion_shakeHeadset_async'] = {
	init: function() {
		this.jsonInit(motionShakeHeadsetAsyncJson);
	}
}

const motionShakeHeadsetWithRepeatSyncJson = {
	"message0": "shake headset once until end",
	"previousStatement": null,
	"nextStatement": null,
	"style": "motion_blocks",
}

Blockly.Blocks['c_motion_shakeHeadsetWithRepeat_sync'] = {
	init: function() {
		this.jsonInit(motionShakeHeadsetWithRepeatSyncJson);
	}
}

const motionShakeHeadsetWithRepeatAsyncJson = {
	"message0": "shake headset %1",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'REPEAT',
			'options': [
				["once", "2"],
				["repeatedly", "0"],
			],
		}
	],
	"previousStatement": null,
	"nextStatement": null,
	"style": "motion_blocks",
}

Blockly.Blocks['c_motion_shakeHeadsetWithRepeat_async'] = {
	init: function() {
		this.jsonInit(motionShakeHeadsetWithRepeatAsyncJson);
	}
}

const motionNoticeOptions = [
	['NOTICE_1', motionList.indexOf('A2_NOTICE_1').toString()],
];

const motionNoticeSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionNoticeSyncJson.args0[0].options = motionNoticeOptions;
Blockly.Blocks['c_motion_notice_sync'] = {
	init: function() {
		this.jsonInit(motionNoticeSyncJson);
	}
}

const motionNoticeAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionNoticeAsyncJson.args0[0].options = motionNoticeOptions;
Blockly.Blocks['c_motion_notice_async'] = {
	init: function() {
		this.jsonInit(motionNoticeAsyncJson);
	}
}

const motionVoiceMailDeliveryEndOptions = [
	['VOICE_MAIL_DELIVERY_END', motionList.indexOf('A3_VOICE_MAIL_DELIVERY_END').toString()],
];

const motionVoiceMailDeliveryEndSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionVoiceMailDeliveryEndSyncJson.args0[0].options = motionVoiceMailDeliveryEndOptions;
Blockly.Blocks['c_motion_voiceMailDeliveryEnd_sync'] = {
	init: function() {
		this.jsonInit(motionVoiceMailDeliveryEndSyncJson);
	}
}

const motionVoiceMailDeliveryEnAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionVoiceMailDeliveryEnAsyncJson.args0[0].options = motionVoiceMailDeliveryEndOptions;
Blockly.Blocks['c_motion_voiceMailDeliveryEnd_async'] = {
	init: function() {
		this.jsonInit(motionVoiceMailDeliveryEnAsyncJson);
	}
}

const motionAwakewordOptions = [
	['AWAKE_WORD_1', motionList.indexOf('A4_AWAKE_WORD_1').toString()],
];

const motionAwakewordSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionAwakewordSyncJson.args0[0].options = motionAwakewordOptions;
Blockly.Blocks['c_motion_awakeword_sync'] = {
	init: function() {
		this.jsonInit(motionAwakewordSyncJson);
	}
}

const motionAwakewordAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionAwakewordAsyncJson.args0[0].options = motionAwakewordOptions;
Blockly.Blocks['c_motion_awakeword_async'] = {
	init: function() {
		this.jsonInit(motionAwakewordAsyncJson);
	}
}

const motionUpDownOptions = [
	['UP_DOWN', motionList.indexOf('UP_DOWN').toString()],
	['DOWN_UP', motionList.indexOf('DOWN_UP').toString()],
];

const motionUpDownSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionUpDownSyncJson.args0[0].options = motionUpDownOptions;
Blockly.Blocks['c_motion_upDown_sync'] = {
	init: function() {
		this.jsonInit(motionUpDownSyncJson);
	}
}

const motionUpDownAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
motionUpDownAsyncJson.args0[0].options = motionUpDownOptions;
Blockly.Blocks['c_motion_upDown_async'] = {
	init: function() {
		this.jsonInit(motionUpDownAsyncJson);
	}
}

// const motionDoNotKnowOptions = [
// 	['DO_NOT_KNOW_1', motionList.indexOf('E12_DO_NOT_KNOW_1').toString()],
// 	['DO_NOT_KNOW_2', motionList.indexOf('E12_DO_NOT_KNOW_2').toString()],
// ];

// const motionDoNotKnowSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
// motionDoNotKnowSyncJson.args0[0].options = motionDoNotKnowOptions;
// Blockly.Blocks['c_motion_doNotKnow_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionDoNotKnowSyncJson);
// 	}
// }

// const motionDoNotKnowAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
// motionDoNotKnowAsyncJson.args0[0].options = motionDoNotKnowOptions;
// Blockly.Blocks['c_motion_doNotKnow_async'] = {
// 	init: function() {
// 		this.jsonInit(motionDoNotKnowAsyncJson);
// 	}
// }

// const motionExclamationOptions = [
// 	['EXCLAMATION_1', motionList.indexOf('E13_EXCLAMATION_1').toString()],		
// ];

// const motionExclamationSyncJson = JSON.parse(JSON.stringify(motionSyncJson));;
// motionExclamationSyncJson.args0[0].options = motionExclamationOptions;
// Blockly.Blocks['c_motion_exclamation_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionExclamationSyncJson);
// 	}
// }

// const motionExclamationAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));;
// motionExclamationAsyncJson.args0[0].options = motionExclamationOptions;
// Blockly.Blocks['c_motion_exclamation_async'] = {
// 	init: function() {
// 		this.jsonInit(motionExclamationAsyncJson);
// 	}
// }

// const motionWithAccelerationOptions = [
// 	['A4_WITH_ACCELERATION_1', motionList.indexOf('A4_WITH_ACCELERATION_1').toString()],
// 	['A4_WITH_ACCELERATION_2', motionList.indexOf('A4_WITH_ACCELERATION_2').toString()],
// ];

// const motionWithAccelerationSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
// motionWithAccelerationSyncJson.args0[0].options = motionWithAccelerationOptions;
// Blockly.Blocks['c_motion_withAcceleration_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionWithAccelerationSyncJson);
// 	}
// }

// const motionWithAccelerationAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
// motionWithAccelerationAsyncJson.args0[0].options = motionWithAccelerationOptions;
// Blockly.Blocks['c_motion_withAcceleration_async'] = {
// 	init: function() {
// 		this.jsonInit(motionWithAccelerationAsyncJson);
// 	}
// }

// const motionWithDecelerationOptions = [
// 	['A5_WITH_DECELERATION_1', motionList.indexOf('A5_WITH_DECELERATION_1').toString()],
// 	['A5_WITH_DECELERATION_2', motionList.indexOf('A5_WITH_DECELERATION_2').toString()],
// ];

// const motionWithDecelerationSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
// motionWithDecelerationSyncJson.args0[0].options = motionWithDecelerationOptions;
// Blockly.Blocks['c_motion_withDeceleration_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionWithDecelerationSyncJson);
// 	}
// }

// const motionWithDecelerationAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
// motionWithDecelerationAsyncJson.args0[0].options = motionWithDecelerationOptions;
// Blockly.Blocks['c_motion_withDeceleration_async'] = {
// 	init: function() {
// 		this.jsonInit(motionWithDecelerationAsyncJson);
// 	}
// }

const motionDanceOptions = [
// 	['DANCE_1', motionList.indexOf('DANCE_1').toString()],
	['DANCE_FUNKY', '901'],
	['DANCE_SWING', '902'],
	['DANCE_EDM', '903'],
];

const motionDanceSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
motionDanceSyncJson.args0[0].options = motionDanceOptions;
Blockly.Blocks['c_motion_dance_sync'] = {
	init: function() {
		this.jsonInit(motionDanceSyncJson);
	}
}

// const motionDanceAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
// motionDanceAsyncJson.args0[0].options = motionDanceOptions;
// Blockly.Blocks['c_motion_dance_async'] = {
// 	init: function() {
// 		this.jsonInit(motionDanceAsyncJson);
// 	}
// }

// const motionDanceEdmCesOptions = [
// 	['DANCE_EDM_FUNKY_CES_LEFT', motionList.indexOf('DANCE_EDM_FUNKY_CES_LEFT').toString()],
// 	['DANCE_EDM_FUNKY_CES_CENTER', motionList.indexOf('DANCE_EDM_FUNKY_CES_CENTER').toString()],
// 	['DANCE_EDM_FUNKY_CES_RIGHT', motionList.indexOf('DANCE_EDM_FUNKY_CES_RIGHT').toString()],
// ];

// const motionDanceEdmCesSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
// motionDanceEdmCesSyncJson.args0[0].options = motionDanceEdmCesOptions;
// Blockly.Blocks['c_motion_danceEdmCes_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionDanceEdmCesSyncJson);
// 	}
// }

// const motionDanceEdmCesAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
// motionDanceEdmCesAsyncJson.args0[0].options = motionDanceEdmCesOptions;
// Blockly.Blocks['c_motion_danceEdmCes_async'] = {
// 	init: function() {
// 		this.jsonInit(motionDanceEdmCesAsyncJson);
// 	}
// }

// const motionDanceKbisOptions = [
// 	['KBIS_DANCE_1', motionList.indexOf('KBIS_DANCE_1').toString()],
// 	['KBIS_DANCE_2', motionList.indexOf('KBIS_DANCE_2').toString()],
// 	['KBIS_DANCE_3', motionList.indexOf('KBIS_DANCE_3').toString()],
// 	['KBIS_DANCE_4', motionList.indexOf('KBIS_DANCE_4').toString()],
// 	['KBIS_DANCE_5', motionList.indexOf('KBIS_DANCE_5').toString()],
// ];

// const motionDanceKbisSyncJson = JSON.parse(JSON.stringify(motionSyncJson));
// motionDanceKbisSyncJson.args0[0].options = motionDanceKbisOptions;
// Blockly.Blocks['c_motion_danceKbis_sync'] = {
// 	init: function() {
// 		this.jsonInit(motionDanceKbisSyncJson);
// 	}
// }

// const motionDanceKbisAsyncJson = JSON.parse(JSON.stringify(motionAsyncJson));
// motionDanceKbisAsyncJson.args0[0].options = motionDanceKbisOptions;
// Blockly.Blocks['c_motion_danceKbis_async'] = {
// 	init: function() {
// 		this.jsonInit(motionDanceKbisAsyncJson);
// 	}
// }

const stopMotionJson = {
	"message0": "stop motion",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "motion_blocks",
}

Blockly.Blocks['c_motion_stopMotion'] = {
	init: function() {
		this.jsonInit(stopMotionJson);
	}
}
// Extensions and Mutators