// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';

// images
// import block_code from '../../assets/images/q9/block_code.png';
import block_code from '../../assets/images/q9/BRIC_Intro1.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[200],
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function HomeIntro() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>        
      <RootStyle>
        <Grid
          container
          direction={isSmallScreen ? 'column' : 'row'}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={7} xl={5} container alignItems="center" justifyContent="center" sx={{ my: 10 }}>
            <Typography variant="h2" component="div" align="center" sx={{ mt: 3, px: 10, color: 'text.info' }}>
              Build your Robot with Interactive Coding
            </Typography>
            <Typography variant="h5" sx={{ mt: 5, px: 10, color: 'text.info' }}>
              API simulator is designed for developers of Q9 applications. 
              Developers can easily experience the APIs provided by the Q9 SDK using this app. 
              Before implementing your program in code, you can see how the APIs work. 
              Simply find the desired function block, drag & drop it, and run it!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} xl={5} container alignItems="center" justifyContent="center" sx={{ mt: isSmallScreen ? 0 : 10, mb: 10 }}>
            <img
              alt="q9_intro"
              src={block_code}       
              style={isSmallScreen ? 
                { width: '70%', height: 'auto', maxWidth: '35vh', marginTop: 30 } : 
                { width: '100%', height: 'auto', maxWidth: '35vh', marginTop: 35 }
              }
            />
          </Grid>
        </Grid>
      </RootStyle>
    </>
  );
}
