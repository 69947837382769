export const toolboxSensing = {
  'kind': 'category',
  'name': 'Sensing',
  'categorystyle': 'sensing_category',
  'contents': [        
    {
      "kind": "label",
      "text": "Sound"
    },
    {
      "kind": "block",
      "type": "c_sensing_startWakewordDetection"
    },
    {
      "kind": "block",
      "type": "c_sensing_wakeWordDetection"
    },
    {
      "kind": "block",
      "type": "c_sensing_stopWakewordDetection"
    },
    {
      "kind": "label",
      "text": "Vision"
    },
    // {
    //   "kind": "block",
    //   "type": "c_sensing_startPersonDetection"
    // },
    {
      "kind": "block",
      "type": "c_sensing_personDetection"
    },
    // {
    //   "kind": "block",
    //   "type": "c_sensing_stopPersonDetection"
    // },
    {
      "kind": "block",
      "type": "c_sensing_faceDetection"
    },
    {
      "kind": "block",
      "type": "c_sensing_numberOfFaceDetected"
    },
    // {
    //   "kind": "label",
    //   "text": "Pose"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_sensing_fallDownDetection"
    // },
    // {
    //   "kind": "block",
    //   "type": "c_sensing_pickUpDetection"
    // },
    {
      "kind": "label",
      "text": "Get Value"
    },
    {
      "kind": "block",
      "type": "c_sensing_headsetAngle"
    },
    {
      "kind": "block",
      "type": "c_sensing_hipAngle"
    },
    {
      "kind": "block",
      "type": "c_sensing_wheelSpeed"
    },
    {
      "kind": "block",
      "type": "c_sensing_batteryLevel"
    },
    {
      "kind": "label",
      "text": "Robot State"
    },
    {
      "kind": "block",
      "type": "c_sensing_allMovementStopped"
    },
    {
      "kind": "block",
      "type": "c_sensing_isDoingNaviTask"
    },
  ],
}