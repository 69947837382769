export const toolboxDeveloper = {
  'kind': 'category',
  'name': 'Developer',
  'categorystyle': 'developer_category',
  'contents': [
    {
      "kind": "label",
      "text": "Text"
    },
    {
      'kind': 'block',
      'type': 'text'
    }, 
    {
      'kind': 'block',
      'type': 'text_join'
    }, 
    // {
    //   'kind': 'block',
    //   'type': 'text_append'
    // },
    {
      "kind": "label",
      "text": "Debugging"
    },
    {
      'kind': 'block',
      'type': 'c_developer_print'
    },        
  ],
}