import Blockly from 'blockly/core';

// block type naming rule : {toolbox_name}_{block_description}

const startWakewordDetectionJson = {
	"message0": "start wakeword detection",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "sensing_blocks",
}

Blockly.Blocks['c_sensing_startWakewordDetection'] = {
	init: function() {
		this.jsonInit(startWakewordDetectionJson);
	}
}

const wakeWordDetectionJson = {
	"message0": "\"Hi! LG\" voice detected",	
	"output": "Boolean",
	"style": "sensing_blocks",
	"tooltip": "Return whether wakeword is detected. Activate wakeword detection function first using start wakeword detection block."
}

Blockly.Blocks['c_sensing_wakeWordDetection'] = {
	init: function() {
		this.jsonInit(wakeWordDetectionJson);
	}
}

const stopWakewordDetectionJson = {
	"message0": "stop wakeword detection",
	"inputsInline": false,
	"previousStatement": null,
	"nextStatement": null,
	"style": "sensing_blocks",
}

Blockly.Blocks['c_sensing_stopWakewordDetection'] = {
	init: function() {
		this.jsonInit(stopWakewordDetectionJson);
	}
}

// const startPersonDetectionJson = {
// 	"message0": "start person detection",
// 	"inputsInline": false,
// 	"previousStatement": null,
// 	"nextStatement": null,
// 	"style": "sensing_blocks",
// }

// Blockly.Blocks['c_sensing_startPersonDetection'] = {
// 	init: function() {
// 		this.jsonInit(startPersonDetectionJson);
// 	}
// }

const personDetectionJson = {
	"message0": "person detected",
	"output": "Boolean",
	"style": "sensing_blocks",
	"tooltip": "Return whether person is detected."
}

Blockly.Blocks['c_sensing_personDetection'] = {
	init: function() {
		this.jsonInit(personDetectionJson);
	}
}

const numberOfFaceDetectedJson = {
	"message0": "number of face detected",
	"output": "Number",
	"style": "sensing_blocks",
	"tooltip": "Return the number of face detected."
}

Blockly.Blocks['c_sensing_numberOfFaceDetected'] = {
	init: function() {
		this.jsonInit(numberOfFaceDetectedJson);
	}
}

// const stopPersonDetectionJson = {
// 	"message0": "stop person detection",
// 	"inputsInline": false,
// 	"previousStatement": null,
// 	"nextStatement": null,
// 	"style": "sensing_blocks",
// }

// Blockly.Blocks['c_sensing_stopPersonDetection'] = {
// 	init: function() {
// 		this.jsonInit(stopPersonDetectionJson);
// 	}
// }

// const fallDownDetectionJson = {
// 	"message0": "fall down detected",
// 	"output": "Boolean",
// 	"style": "sensing_blocks"
// }

// Blockly.Blocks['c_sensing_fallDownDetection'] = {
// 	init: function() {
// 		this.jsonInit(fallDownDetectionJson);
// 	}
// }

// const pickUpDetectionJson = {
// 	"message0": "pick up detected",
// 	"output": "Boolean",
// 	"style": "sensing_blocks"
// }

// Blockly.Blocks['c_sensing_pickUpDetection'] = {
// 	init: function() {
// 		this.jsonInit(pickUpDetectionJson);
// 	}
// }

const headsetAngleJson = {
	"message0": "angle of headset",
	"output": "Number",
	"style": "sensing_blocks",
	"tooltip": "Return the current headset angle."
}

Blockly.Blocks['c_sensing_headsetAngle'] = {
	init: function() {
		this.jsonInit(headsetAngleJson);
	}
}

const hipAngleJson = {
	"message0": "angle of %1 hip",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['LEFT', 'left'],
				['RIGHT', 'right']
			],
		},
	],
	"output": "Number",
	"style": "sensing_blocks",
	"tooltip": "Return the current angle of selected hip."
}

Blockly.Blocks['c_sensing_hipAngle'] = {
	init: function() {
		this.jsonInit(hipAngleJson);
	}
}

const wheelSpeedJson = {
	"message0": "speed of %1 wheel",
	"args0": [
		{
			'type': 'field_dropdown',
			'name': 'DIRECTION',
			'options': [			
				['LEFT', 'left'],
				['RIGHT', 'right']
			],
		},
	],
	"output": "Number",
	"style": "sensing_blocks",
	"tooltip": "Return the current speed of selected wheel."
}

Blockly.Blocks['c_sensing_wheelSpeed'] = {
	init: function() {
		this.jsonInit(wheelSpeedJson);
	}
}

const batteryLevelJson = {
	"message0": "battery level remaining(%)",
	"output": "Number",
	"style": "sensing_blocks",
	"tooltip": "Return the current battery level."
}

Blockly.Blocks['c_sensing_batteryLevel'] = {
	init: function() {
		this.jsonInit(batteryLevelJson);
	}
}

const allMovementStoppedJson = {
	"message0": "all movement stopped",
	"output": "Boolean",
	"style": "sensing_blocks",
	"tooltip": "Return whether all joint is stopped."
}

Blockly.Blocks['c_sensing_allMovementStopped'] = {
	init: function() {
		this.jsonInit(allMovementStoppedJson);
	}
}

const isDoingNavigationTaskJson = {
	"message0": "is doing navigation task",
	"output": "Boolean",
	"style": "sensing_blocks",
	"tooltip": "Return whether robot is doing navigation task."
}

Blockly.Blocks['c_sensing_isDoingNaviTask'] = {
	init: function() {
		this.jsonInit(isDoingNavigationTaskJson);
	}
}

// Extensions and Mutators