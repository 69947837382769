import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// mui
import { 
  Alert, 
  Button, 
  Container, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Paper, 
  Radio,
  RadioGroup,
  Snackbar, 
  Stack, 
  TextField, 
  Typography 
} from "@mui/material";
import { styled } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// context
import { robotConnContext } from '../contexts/ConnectContext';
// Blockly component
import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
import { javascriptGenerator } from "blockly/javascript";
import { Backpack } from "@blockly/workspace-backpack";
// custom Blockly component
import '../components/workspace/CustomBlocks/blocks';
import { logicGenerator, developerGenerator } from '../components/workspace/Generators/generators';
import { saveWorkspace, loadWorkspace } from "../components/workspace/Serialization/Serialization";
import workspaceOptions from "../components/workspace/workspaceOption";
import backpackOptions from "../components/workspace/backpackOption";
// custom component
import WsConnector from "../components/workspace/Robot/wsConnector";
import StyledButton from "../components/StyledButton";
import StyledTooltip from "../components/StyledTooltip";
import { LoadingButtonText, Spinner } from "../components/LoadingButton";
// import { generateCppCode } from "../components/workspace/CppCodeSnippet/codeSnippet";
import apiInfo from "../components/workspace/CppCodeSnippet/apiInfo";
// external component
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import 'highlight.js/styles/github.css';
import Interpreter from "js-interpreter/lib/js-interpreter";
// for debugging
import { IS_LOGGING_ON } from "../config";
import { Logger } from "../logger";

Blockly.setLocale(locale);
hljs.registerLanguage('javascript', javascript);

const RootStyle = styled('div')(({ theme }) => ({
  // '@global': {
  //   '.blocklyHighlightedConnectionPath': {
  //     stroke: 'red !important', // 예: 빨간색 테두리
  //     strokeWidth: '4px !important',
  //   },
  // },
  position: 'relative',
  left: 0,
  // top: 52,              // header height 52px
  top: 0,
  width: '100%',
  height: 'calc(100vh)'  
  // height: 'calc(100vh - 52px)'  
}));

const vertical = 'bottom', horizontal = 'center';     // for snackbar position
const finishCheckerInterval = 100;    // milliseconds
const startAsyncInterval = 100;    // milliseconds
const stopCheckerInterval = 100;    // milliseconds
const updateRecentValueTimeout = 1;   // milliseconds
const detectTimeDuration = 5;    // seconds, wakeword detection time duration
const loopTrapCountNumber = 1000;   
const highlightBlockMinDuration = 200;   // milliseconds

function Workspace() {
  const navigate = useNavigate();
  const { 
    setIsReadyContext, 
    robotIPContext, setRobotIPContext,
    imageFileList, setImageFileList,
    audioFileList, setAudioFileList,
    registeredUserList, setRegisteredUserList,
    spaceList, setSpaceList 
  } = useContext(robotConnContext);
  const { state } = useLocation();

  const blocklyDiv = useRef();
  const workspace = useRef();
  const currentWS = useRef();
  const workspaceNameRef = useRef('');
  const robotRef = useRef(null);
  let myInterpreter = useRef(null);
  let runnerPid = useRef(0);  
  let intervalPid = useRef(0);
  let loopTrapCounterRef = useRef(loopTrapCountNumber);   
  let isTrappedInInfiniteLoop = useRef(false);  
  
  let textFromSpeechRef = useRef('');
  // let isRecordingFinishedRef = useRef(false);
  
  let lastTimeWakewordDetectedRef = useRef(null);
  let personDetectedRef = useRef(null);
  let faceDetectedUserListRef = useRef(null);  
  // let isFalldownRef = useRef(null);
  // let isPickUpRef = useRef(null);
  let angleOfHeadsetRef = useRef(null);
  let angleOfLeftHipRef = useRef(null);
  let angleOfRightHipRef = useRef(null);
  let speedOfLeftWheelRef = useRef(null);
  let speedOfRightWheelRef = useRef(null);
  let batteryLevelRef = useRef(null);
  let isSucceededPrepareNaviRef = useRef(null);
  let isSucceededCreateMapRef = useRef(null);
  let isSucceededGoHomeRef = useRef(null);
  let isSucceededDockToChargerRef = useRef(null);
  let isSucceededUndockFromChargerRef = useRef(null);
  let isSucceededExploreNodesRef = useRef(null);
  let isSucceededNaviToSpaceRef = useRef(null);
  let isSucceededSpinToFindPersonRef = useRef(null);
  let isSucceededEyeContactRef = useRef(null);
  let isSucceededComeHereRef = useRef(null);
  let isSucceededFollowMeRef = useRef(null);

  // face expression loop, play sound(effect, file, tts), motion, headset, hip, wheel(move forward/backward, spin, move along turning radius), navigation
  let isRepeatingFaceAnimationRef = useRef(false);
  let playingSoundTypeRef = useRef(''); 
  let isDoingMotionRef = useRef(false);  
  let isMovingHeadsetRef = useRef(false);
  let isMovingHipRef = useRef(false);
  let runningWheelTaskRef = useRef('');
  let isRunningNaviTaskRef = useRef(false);
  let isStoppingNaviTaskRef = useRef(false);

  const [is1stLoading, setIs1stLoading] = useState(true);
  const [openNotReadyDialog, setOpenNotReadyDialog] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [viewCode, setViewCode] = useState(false);
  const [generatedCode, setGeneratedCode] = useState('');  
  // const [viewCppCodeSnippet, setViewCppCodeSnippet] = useState(false);
  // const [cppCodeSnippet, setCppCodeSnippet] = useState('');  
  const [viewAPIInfoData, setViewAPIInfoData] = useState(false);
  const [apiInfoData, setAPIInfoData] = useState('');  
  const [filesToSend, setFilesToSend] = useState([]);
  const [fileType, setFileType] = useState('');
  const [openSendFileDialog, setOpenSendFileDialog] = useState(false);
  const [openSendFileSuccess, setOpenSendFileSuccess] = useState(false);
  const [openNoSelectedFile, setOpenNoSelectedFile] = useState(false);
  const [loadingSendFile, setLoadingSendFile] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openSaveWSSuccess, setOpenSaveWSSuccess] = useState(false);  
  const [openDisconnectRobotDialog, setOpenDisconnectRobotDialog] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [runBtnMsg, setRunBtnMsg] = useState('Run');
  const [isWaitingUpdateBlock, setIsWaitingUpdateBlock] = useState(false);
  const [openInfiniteLoopDialog, setOpenInfiniteLoopDialog] = useState(false);
  const [openCopyToClipboardSuccess, setOpenCopyToClipboardSuccess] = useState(false);
  const [openCopyToClipboardFail, setOpenCopyToClipboardFail] = useState(false);
  
  // if robot ip is not configured, pop up dialog for navigating to home page."
  useEffect(() => {
    if (!robotIPContext) setOpenNotReadyDialog(true);
  }, [])

  useEffect(() => {
    if (isStopping) setRunBtnMsg('Stopping');
    else {
      isRunning ? setRunBtnMsg('Stop') : setRunBtnMsg('Run');
    }
  }, [isRunning, isStopping])

  function registerContextMenuOptions() {
    const robotBlockStyles = [
      'interaction_blocks',
      'motion_blocks',
      'movement_blocks',
      'sensing_blocks',
      'navigation_blocks'
    ]
    const blockItem = {
      displayText: 'Open API Info',
      preconditionFn: function(scope) {
        if(!scope.block.isInFlyout && robotBlockStyles.includes(scope.block.getStyleName())){
          return 'enabled';
        } else {
          return 'hidden';
        }        
      },
      callback: function(scope) {        
        // console.log('callback for clicking api info context menu');
        const blockType = scope.block.type;
        if(blockType.includes('c_motion')){
          let apiInformation = '// Provider\n';;
          if(blockType === 'c_motion_stopMotion'){
            apiInformation += apiInfo[blockType].provider;
            apiInformation += '// API function\n';
            apiInformation += apiInfo[blockType].function;
            apiInformation += '// Callback function\n';
            apiInformation += apiInfo[blockType].callback;            
          } else {            
            apiInformation += apiInfo['c_motion_syncAsync'].provider;
            apiInformation += '// API function\n';
            apiInformation += apiInfo['c_motion_syncAsync'].function;
            apiInformation += '// Callback function\n';
            if(blockType.includes('async')){
              apiInformation += apiInfo['c_motion_syncAsync'].callbackAsync;
            } else {
              apiInformation += apiInfo['c_motion_syncAsync'].callbackSync;
            }            
          }
          setAPIInfoData(apiInformation);
          setViewAPIInfoData(true);
        } else if(Object.keys(apiInfo).includes(blockType)){
          let apiInformation = '// Provider\n';
          apiInformation += apiInfo[blockType].provider;
          apiInformation += '// API function\n';
          apiInformation += apiInfo[blockType].function;
          apiInformation += '// Callback function\n';
          apiInformation += apiInfo[blockType].callback;
          setAPIInfoData(apiInformation);
          setViewAPIInfoData(true);
        } else {
          let noAPIInfoMsg = '// There is no api information. It will be updated soon.\n';
          setAPIInfoData(noAPIInfoMsg);
          setViewAPIInfoData(true);
        }
      },
      scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
      id: 'api_info',
      weight: 100,
    };
    Blockly.ContextMenuRegistry.registry.register(blockItem);
  }

  // set workspace options and inject to blocklyDiv
  useEffect(() => {     
    if(Blockly.ContextMenuRegistry.registry.getItem('api_info')) Blockly.ContextMenuRegistry.registry.unregister('api_info');
    registerContextMenuOptions();
    if (!workspace.current) {
      Logger('1st loading. create new workspace');   
      setIs1stLoading(false); 
      updateDisplayImageBlock();
      updatePlaySoundBlock();
      updateUserListBlock();
      updateSpaceListBlock();
      workspace.current = Blockly.inject(blocklyDiv.current, workspaceOptions);
      const backpack = new Backpack(workspace.current, backpackOptions);     
      backpack.init();
      backpack.onDragEnter(function(){
        console.log('mouse over');
      });      
      initBlocksOnWorkspace();
      // To generate new code for blocks in the current workspace
      workspace.current.addChangeListener(updateCode);      
    } else {
      Logger('workspace refreshing because of updated file list');    
      Blockly.Events.disable();
      if(IS_LOGGING_ON) console.log('loading currentWS:', currentWS.current);
      Blockly.serialization.workspaces.load(currentWS.current, workspace.current);
      Blockly.Events.enable();   
    }    
    updateCode();
  }, [currentWS.current])  

  useEffect(() => {     
    if (!is1stLoading) updateBlock('displayImge');      
  }, [imageFileList]) 

  useEffect(() => {    
    if(is1stLoading) return;
    if(isRunning) {
      setIsWaitingUpdateBlock(true);
      return;
    }        
    updateBlock('playSound');    
  }, [audioFileList]) 

  useEffect(() => {    
    if(is1stLoading || isRunning) return;  
    if(isWaitingUpdateBlock) {
      updateBlock('playSound');    
      setIsWaitingUpdateBlock(false);
    }
  }, [isRunning]) 

  function updateBlock(blockType) {
    switch(blockType){
      case 'displayImge': 
        Logger('image file list updated. updating displayImage block...');
        updateDisplayImageBlock();
        break;
      case 'playSound': 
        Logger('audio file list updated. updating playSound blocks...');
        updatePlaySoundBlock();
        break;
      default:          
    }
    currentWS.current = Blockly.serialization.workspaces.save(workspace.current);
    if(IS_LOGGING_ON) console.log('saving currentWS:', currentWS.current);
    workspace.current.clear();
  }
  
  function updateDisplayImageBlock() {
    Blockly.Blocks['c_interaction_displayImage'] = {
      init: function() {
        this.appendDummyInput()
            .appendField('display image file')
            .appendField(new Blockly.FieldDropdown(imageFileList), 'IMAGE')
        this.setStyle('interaction_blocks');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
      }
    };
  }

  function updatePlaySoundBlock() {
    Blockly.Blocks['c_interaction_playSound_sync'] = {
      init: function() {
        this.appendDummyInput()
            .appendField('play sound file')
            .appendField(new Blockly.FieldDropdown(audioFileList), 'SOUND')
            .appendField('until end');
        this.setStyle('interaction_blocks');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
      }
    };
    Blockly.Blocks['c_interaction_playSound_async'] = {
      init: function() {
        this.appendDummyInput()
            .appendField('play sound file')
            .appendField(new Blockly.FieldDropdown(audioFileList), 'SOUND')     
        this.setStyle('interaction_blocks');
        this.setPreviousStatement(true);
        this.setNextStatement(true);
      }
    };
  }

  function updateUserListBlock() { 
    Blockly.Blocks['c_sensing_faceDetection'] = {
      init: function() {
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(registeredUserList), 'USERLIST')
            .appendField('is detected');
        this.setStyle('sensing_blocks');
        this.setOutput(true, 'Boolean');
        this.setTooltip("Return whether selected user is detected.");
      }
    };

    const naviJson = {
      "args0": [
        {
          'type': 'field_dropdown',
          'name': 'USERLIST',
          'options': registeredUserList,
          // 'options': [
          //   ['Anyone', '0']
          // ]
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "style": "navigation_blocks",
    }

    const spinToFindPersonSyncJson = JSON.parse(JSON.stringify(naviJson));
    spinToFindPersonSyncJson.message0 = "spin to find person %1 until done";
    spinToFindPersonSyncJson.tooltip = "When this block is executed, robot will rotate left and right to find the selected user. If the user is found, robot will stop in that direction, otherwise it will search 360 degrees and end."
    const spinToFindPersonAsyncJson = JSON.parse(JSON.stringify(naviJson));
    spinToFindPersonAsyncJson.message0 = "spin to find person %1";
    spinToFindPersonAsyncJson.tooltip = "When this block is executed, robot will rotate left and right to find the selected user. If the user is found, robot will stop in that direction, otherwise it will search 360 degrees and end."
    Blockly.Blocks['c_navigation_spinToFindPerson_sync'] = {
      init: function() {
        this.jsonInit(spinToFindPersonSyncJson);
      }
    }
    Blockly.Blocks['c_navigation_spinToFindPerson_async'] = {
      init: function() {
        this.jsonInit(spinToFindPersonAsyncJson);
      }
    }

    // const contactEyesSyncJson = JSON.parse(JSON.stringify(naviJson));
    // contactEyesSyncJson.message0 = "make eye contact with %1 until done";
    const contactEyesAsyncJson = JSON.parse(JSON.stringify(naviJson));
    contactEyesAsyncJson.message0 = "make eye contact with %1";
    contactEyesAsyncJson.tooltip = "This block will continue to run unless explicitly cancelled using cancel navigation task block or terminated by another task request."
    // Blockly.Blocks['c_navigation_contactEyes_sync'] = {
    //   init: function() {
    //     this.jsonInit(contactEyesSyncJson);
    //   }
    // }
    Blockly.Blocks['c_navigation_contactEyes_async'] = {
      init: function() {
        this.jsonInit(contactEyesAsyncJson);
      }
    }

    const comeHereSyncJson = JSON.parse(JSON.stringify(naviJson));
    comeHereSyncJson.message0 = "come to %1 until done";
    comeHereSyncJson.tooltip = "The user must be in the camera view when this block is executed, otherwise it will be automatically canceled."
    const comeHereAsyncJson = JSON.parse(JSON.stringify(naviJson));
    comeHereAsyncJson.message0 = "come to %1";
    comeHereAsyncJson.tooltip = "The user must be in the camera view when this block is executed, otherwise it will be automatically canceled."
    Blockly.Blocks['c_navigation_comeHere_sync'] = {
      init: function() {
        this.jsonInit(comeHereSyncJson);
      }
    }
    Blockly.Blocks['c_navigation_comeHere_async'] = {
      init: function() {
        this.jsonInit(comeHereAsyncJson);
      }
    }

    // const followMeSyncJson = JSON.parse(JSON.stringify(naviJson));
    // followMeSyncJson.message0 = "follow %1 until done";
    const followMeAsyncJson = JSON.parse(JSON.stringify(naviJson));
    followMeAsyncJson.message0 = "follow %1";
    followMeAsyncJson.tooltip = "This block will continue to run unless explicitly cancelled using cancel navigation task block or terminated by another task request."
    // Blockly.Blocks['c_navigation_followMe_sync'] = {
    //   init: function() {
    //     this.jsonInit(followMeSyncJson);
    //   }
    // }
    Blockly.Blocks['c_navigation_followMe_async'] = {
      init: function() {
        this.jsonInit(followMeAsyncJson);
      }
    }
  }

  function updateSpaceListBlock() {
    const navigateToSpaceJson = {      
      "args0": [
        {
          'type': 'field_dropdown',
          'name': 'SPACELIST',
          'options': spaceList,
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "style": "navigation_blocks",
    }

    const navigateToSpaceSyncJson = JSON.parse(JSON.stringify(navigateToSpaceJson));
    navigateToSpaceSyncJson.message0 = "navigate to space %1 until done";
    const navigateToSpaceAsyncJson = JSON.parse(JSON.stringify(navigateToSpaceJson));
    navigateToSpaceAsyncJson.message0 = "navigate to space %1";
    Blockly.Blocks['c_navigation_navigateToSpace_sync'] = {
      init: function() {
        this.jsonInit(navigateToSpaceSyncJson);
      }
    }
    Blockly.Blocks['c_navigation_navigateToSpace_async'] = {
      init: function() {
        this.jsonInit(navigateToSpaceAsyncJson);
      }
    }
  }

  function initBlocksOnWorkspace() {
    const width = blocklyDiv.current.offsetWidth;
    const height = blocklyDiv.current.offsetHeight;
    const posX = width / 4;
    const posY = height / 8;

    Logger(`robot ip: ${robotIPContext}`);

    if (state && state.savedWorkspace){
      if(IS_LOGGING_ON) console.log('state.savedWorkspace:', state.savedWorkspace);
      setWorkspaceName(state.savedWorkspace);      
      workspaceNameRef.current = state.savedWorkspace;
      loadWorkspace(workspace.current, state.savedWorkspace);
    } else {
      Logger('put start block');
      const startBlock = {
        blocks: {
          blocks: [
            {
              "type": "c_program_start",
              "x": posX,
              "y": posY,
            }
          ]
        }
      }
      Blockly.Events.disable();  
      Blockly.serialization.workspaces.load(startBlock, workspace.current);
      Blockly.Events.enable();
      let blocks = workspace.current.getAllBlocks(false);
      blocks[0].setDeletable(false);
      blocks[0].setMovable(false);
    }    
  }    

  function updateCode() {    
    // blocks that aren't attached to the start block will be disabled
    let blocks = workspace.current.getAllBlocks(false);
    let withStepUI = false;
    blocks.forEach((block) => {
      if(block.type === 'c_program_start') {        
        withStepUI = block.getFieldValue('STEP') === 'TRUE';
      }
      if(block.getRootBlock().type !== 'c_program_start'){
        block.setEnabled(false);
        block.setWarningText('Need to be attached to the start block tree.');
      } else {
        if(block.type === 'c_interaction_displayImage' && block.getFieldValue('IMAGE') === 'no_files'){          
          block.setEnabled(false);
          block.setWarningText('There are no files to play. Please send files to robot first.');          
        } else if(block.type.includes('c_interaction_playSound') && block.getFieldValue('SOUND') === 'no_files'){
          block.setEnabled(false);
          block.setWarningText('There are no files to play. Please send files to robot first.');          
        } else if(block.type.includes('c_navigation_navigateToSpace') && block.getFieldValue('SPACELIST') === 'no_space'){
          block.setEnabled(false);
          block.setWarningText('There are no space list to navigate. Please create map first.');          
        } else {
          block.setEnabled(true);
          block.setWarningText('');
        }
      }
    })    
    if(withStepUI) {
      javascriptGenerator.STATEMENT_PREFIX = 'highlightBlock(%1, true);\n';
      javascriptGenerator.addReservedWords('highlightBlock');
    } else javascriptGenerator.STATEMENT_PREFIX = null;
    const code = javascriptGenerator.workspaceToCode(workspace.current);
    setGeneratedCode(code);
    // const cppCode = generateCppCode(workspace.current, workspaceNameRef.current);
    // setCppCodeSnippet(cppCode);
  }
  
  // init API function for javascript interpreter
  function initApi(interpreter, globalObject) {
    // interaction block
    const wrapperStartCode = interpreter.createNativeFunction(
      function () {
        return robotRef.current.startCode();
    });
    interpreter.setProperty(globalObject, 'startCode', wrapperStartCode);
    // interaction block
    const wrapperPlayFacialExpression = interpreter.createNativeFunction(
      function (facialExpression, repeat) {        
        return robotRef.current.playFacialExpression(facialExpression, repeat);
    });
    interpreter.setProperty(globalObject, 'playFacialExpression', wrapperPlayFacialExpression);

    const wrapperPlayFacialExpressionAsync = interpreter.createAsyncFunction(
      function (facialExpression, repeat, callback) {
        intervalPid.current = setInterval(function(){       
          Logger(`play face expression ${facialExpression} repeat ${repeat}`);
          if (repeat === 1) isRepeatingFaceAnimationRef.current = true;
          else if (repeat === 0) isRepeatingFaceAnimationRef.current = false;
          robotRef.current.playFacialExpression(facialExpression, repeat);
          clearInterval(intervalPid.current);
          callback();          
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'playFacialExpressionAsync', wrapperPlayFacialExpressionAsync);

    const wrapperDisplayImage = interpreter.createNativeFunction(
      function (image) {
        return robotRef.current.displayImage(image);
    });
    interpreter.setProperty(globalObject, 'displayImage', wrapperDisplayImage);

    const wrapperDisplayText = interpreter.createNativeFunction(
      function (text) {          
        return robotRef.current.displayText(text);
    });
    interpreter.setProperty(globalObject, 'displayText', wrapperDisplayText);

    const wrapperDisplayOnOff = interpreter.createNativeFunction(
      function (onOff) {
        return robotRef.current.displayOnOff(onOff);
    });
    interpreter.setProperty(globalObject, 'displayOnOff', wrapperDisplayOnOff);

    const wrapperSetBrightness = interpreter.createNativeFunction(
      function (level) {
        return robotRef.current.setBrightness(level);
    });
    interpreter.setProperty(globalObject, 'setBrightness', wrapperSetBrightness);

    const wrapperUpdateToastMessage = interpreter.createNativeFunction(
      function (text) {          
        return robotRef.current.updateToastMessage(text);
    });
    interpreter.setProperty(globalObject, 'updateToastMessage', wrapperUpdateToastMessage);

    const wrapperDismissToastMessage = interpreter.createNativeFunction(
      function () {          
        return robotRef.current.dismissToastMessage();
    });
    interpreter.setProperty(globalObject, 'dismissToastMessage', wrapperDismissToastMessage);

    const wrapperPlayEmotionSound = interpreter.createNativeFunction(
      function (sound) {
        return robotRef.current.playEmotionSound(sound);
    });
    interpreter.setProperty(globalObject, 'playEmotionSound', wrapperPlayEmotionSound);

    const wrapperPlayEmotionSoundAsync = interpreter.createAsyncFunction(
      function (sound, callback) {
        intervalPid.current = setInterval(function(){       
          Logger(`play emotion sound ${sound}`);
          playingSoundTypeRef.current = 'emotion';          
          robotRef.current.playEmotionSound(sound);
          clearInterval(intervalPid.current);
          callback();          
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'playEmotionSoundAsync', wrapperPlayEmotionSoundAsync);

    const wrapperPlayEffectSoundAsync = interpreter.createAsyncFunction(
      function (sound, callback) {
        intervalPid.current = setInterval(function(){       
          Logger(`play effect sound ${sound}`);
          playingSoundTypeRef.current = 'effect';          
          robotRef.current.playEffectSound(sound);
          clearInterval(intervalPid.current);
          callback();          
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'playEffectSoundAsync', wrapperPlayEffectSoundAsync);

    const wrapperPlaySound = interpreter.createNativeFunction(
      function (sound) {
        return robotRef.current.playSound(sound);
    });
    interpreter.setProperty(globalObject, 'playSound', wrapperPlaySound);

    const wrapperPlaySoundAsync = interpreter.createAsyncFunction(
      function (sound, callback) {
        intervalPid.current = setInterval(function(){       
          Logger(`play sound file ${sound}`);
          playingSoundTypeRef.current = 'file';          
          robotRef.current.playSound(sound);
          clearInterval(intervalPid.current);
          callback();          
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'playSoundAsync', wrapperPlaySoundAsync);

    const wrapperPlayTTS = interpreter.createNativeFunction(
      function (text) {          
        return robotRef.current.playTTS(text);
    });
    interpreter.setProperty(globalObject, 'playTTS', wrapperPlayTTS);

    const wrapperPlayTTSAsync = interpreter.createAsyncFunction(
      function (text, callback) {          
        intervalPid.current = setInterval(function(){       
          Logger(`play tts ${text}`);
          playingSoundTypeRef.current = 'tts';          
          robotRef.current.playTTS(text);
          clearInterval(intervalPid.current);
          callback();          
        }, startAsyncInterval) 
    });
    interpreter.setProperty(globalObject, 'playTTSAsync', wrapperPlayTTSAsync);

    // Argument passing between browser and js-interpreter occurs during program execution. 
    // Therefore, to retrieve the latest updated value from the browser to the interpreter, 
    // the program must be rerun, and an async function wrapped as createAsyncFunction wrapper 
    // is used for this purpose.
    const wrapperPlaySoundFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isFinished = playingSoundTypeRef.current === '';                
          if(isFinished) {            
            Logger('playSound finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)
    });
    interpreter.setProperty(globalObject, 'soundFinishChecker', wrapperPlaySoundFinishChecker);   

    const wrapperStopSoundAsync = interpreter.createAsyncFunction(
      function (callback) {       
        intervalPid.current = setInterval(function(){    
          if(playingSoundTypeRef.current !== ''){
            Logger(`stop sound type ${playingSoundTypeRef.current}`);
            robotRef.current.stopSound(playingSoundTypeRef.current);
          }
          if(playingSoundTypeRef.current === '') {            
            Logger(`stop sound done`);            
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'stopSoundAsync', wrapperStopSoundAsync);
    
    const wrapperStartSTT = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startSTT();  
    });
    interpreter.setProperty(globalObject, 'startSTT', wrapperStartSTT); 

    const wrapperSttFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const textFromSpeech = textFromSpeechRef.current;                     
          if(textFromSpeech !== '') {
            Logger('stt finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'sttFinishChecker', wrapperSttFinishChecker);

    const wrapperGetTextFromSpeech = interpreter.createNativeFunction(
      function () {
        const text = textFromSpeechRef.current;
        textFromSpeechRef.current = '';
        // Logger('text from speech:', text);
        return text;
    });
    interpreter.setProperty(globalObject, 'getTextFromSpeech', wrapperGetTextFromSpeech);

    const wrapperStartVoiceRecording = interpreter.createNativeFunction(
      function () { 
        return robotRef.current.startVoiceRecording();
    });
    interpreter.setProperty(globalObject, 'startVoiceRecording', wrapperStartVoiceRecording);

    // const wrapperVoiceRecordingFinishChecker = interpreter.createAsyncFunction(
    //   function (callback) {                   
    //     intervalPid.current = setInterval(function(){                      
    //       const isFinished = isRecordingFinishedRef.current;
    //       if(isFinished) {            
    //         isRecordingFinishedRef.current = false;
    //         Logger('voice recording finished.');
    //         clearInterval(intervalPid.current);
    //         callback();
    //       }
    //     }, finishCheckerInterval)        
    // });
    // interpreter.setProperty(globalObject, 'voiceRecordingFinishChecker', wrapperVoiceRecordingFinishChecker);
    
    const wrapperVolumeControl = interpreter.createNativeFunction(
      function (vol) { 
        return robotRef.current.volumeControl(vol);
    });
    interpreter.setProperty(globalObject, 'volumeControl', wrapperVolumeControl);

    // motion blocks
    const wrapperStartMotion = interpreter.createNativeFunction(
      function (motionId) {      
        return robotRef.current.startMotion(motionId);  
    });
    interpreter.setProperty(globalObject, 'startMotion', wrapperStartMotion);

    const wrapperStartMotionAsync = interpreter.createAsyncFunction(
      function (motionId, callback) {      
        intervalPid.current = setInterval(function(){           
          // if(isDoingMotionRef.current){
          //   Logger(`stop motion for new motion`);
          //   robotRef.current.stopMotion();
          // } 
          // if(isMovingHeadsetRef.current){
          //   Logger(`stop headset for new motion`);
          //   robotRef.current.stopHeadset();
          // }
          // if(isMovingHipRef.current){
          //   Logger(`stop hip for new motion`);
          //   robotRef.current.stopHip();
          // }
          // if(isRunningNaviTaskRef.current !== ''){
          //   Logger(`stop navi task for new motion`);
          //   robotRef.current.cancelNaviTask();
          // }
          // if(runningWheelTaskRef.current !== ''){
          //   Logger(`stop wheel task for new motion`);
          //   robotRef.current.stopWheel(runningWheelTaskRef.current);
          // }
          if(!isDoingMotionRef.current && !isMovingHeadsetRef.current && !isMovingHipRef.current && runningWheelTaskRef.current === '' && !isRunningNaviTaskRef.current) {
            Logger(`start motion ${motionId}`);
            isDoingMotionRef.current = true;
            robotRef.current.startMotion(motionId);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'startMotionAsync', wrapperStartMotionAsync);

    const wrapperStartInPlaceMotionWithRepeatAsync = interpreter.createAsyncFunction(
      function (motionId, repeat, callback) {      
        intervalPid.current = setInterval(function(){           
          if(isDoingMotionRef.current){
            Logger(`stop motion for new motion`);
            robotRef.current.stopMotion();
          } 
          if(isMovingHeadsetRef.current){
            Logger(`stop headset for new motion`);
            robotRef.current.stopHeadset();
          }
          if(isMovingHipRef.current){
            Logger(`stop hip for new motion`);
            robotRef.current.stopHip();
          }
          if(!isDoingMotionRef.current && !isMovingHeadsetRef.current && !isMovingHipRef.current) {
            Logger(`start in place motion ${motionId}`);
            isDoingMotionRef.current = true;
            robotRef.current.startInPlaceMotion(motionId, repeat);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)   
    });
    interpreter.setProperty(globalObject, 'startInPlaceMotionWithRepeatAsync', wrapperStartInPlaceMotionWithRepeatAsync);

    const wrapperMotionFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isMotionFinished = !isDoingMotionRef.current;                
          if(isMotionFinished) {            
            Logger('motion finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'motionFinishChecker', wrapperMotionFinishChecker);

    const wrapperStopMotion = interpreter.createNativeFunction(
      function () {
        return robotRef.current.stopMotion();
    });
    interpreter.setProperty(globalObject, 'stopMotion', wrapperStopMotion);  

    //movement blocks
    // const wrapperRotateHeadset = interpreter.createNativeFunction(
    //   function (targetAngle, seconds) {      
    //     robotRef.current.rotateHeadset(targetAngle, seconds);
    // });
    // interpreter.setProperty(globalObject, 'rotateHeadset', wrapperRotateHeadset);

    const wrapperRotateHeadsetAsync = interpreter.createAsyncFunction(
      function (targetAngle, selectedSpeed, callback) {      
        intervalPid.current = setInterval(function(){      
          if(isDoingMotionRef.current){
            Logger(`stop motion for new headset motion`);
            robotRef.current.stopMotion();
          } 
          if(isMovingHeadsetRef.current){
            Logger(`stop headset for new headset motion`);
            robotRef.current.stopHeadset();
          }
          if(!isDoingMotionRef.current && !isMovingHeadsetRef.current) {
            Logger(`rotate headset`);
            isMovingHeadsetRef.current = true;
            robotRef.current.rotateHeadset(targetAngle, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)          
    });
    interpreter.setProperty(globalObject, 'rotateHeadsetAsync', wrapperRotateHeadsetAsync);    

    const wrapperHeadsetMotionFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isFinished = !isMovingHeadsetRef.current;                
          if(isFinished) {                        
            Logger('headset motion finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'headsetMotionFinishChecker', wrapperHeadsetMotionFinishChecker);

    const wrapperStopHeadset = interpreter.createNativeFunction(
      function () {       
        return robotRef.current.stopHeadset();
    });
    interpreter.setProperty(globalObject, 'stopHeadset', wrapperStopHeadset);

    const wrapperMoveBothHip = interpreter.createNativeFunction(
      function (targetAngleL, targetAngleR, selectedSpeed) {         
        robotRef.current.moveBothHip(targetAngleL, targetAngleR, selectedSpeed);
    });
    interpreter.setProperty(globalObject, 'moveBothHip', wrapperMoveBothHip); 

    const wrapperMoveBothHipAsync = interpreter.createAsyncFunction(
      function (targetAngleL, targetAngleR, selectedSpeed, callback) {         
        intervalPid.current = setInterval(function(){      
          if(isDoingMotionRef.current){
            Logger(`stop motion for new hip motion`);
            robotRef.current.stopMotion();
          } 
          if(isMovingHipRef.current){
            Logger(`stop hip for new hip motion`);
            robotRef.current.stopHip();
          }
          if(!isDoingMotionRef.current && !isMovingHipRef.current) {
            Logger(`move both hip`);
            isMovingHipRef.current = true;
            robotRef.current.moveBothHip(targetAngleL, targetAngleR, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval) 
    });
    interpreter.setProperty(globalObject, 'moveBothHipAsync', wrapperMoveBothHipAsync);       

    const wrapperMoveOneHipAsync = interpreter.createAsyncFunction(
      function (selectedHip, targetAngle, selectedSpeed, callback) {         
        intervalPid.current = setInterval(function(){      
          if(isDoingMotionRef.current){
            Logger(`stop motion for new hip motion`);
            robotRef.current.stopMotion();
          } 
          if(isMovingHipRef.current){
            Logger(`stop hip for new hip motion`);
            robotRef.current.stopHip();
          }
          if(!isDoingMotionRef.current && !isMovingHipRef.current) {
            Logger(`move one hip`);
            isMovingHipRef.current = true;
            robotRef.current.moveOneHip(selectedHip, targetAngle, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval) 
    });
    interpreter.setProperty(globalObject, 'moveOneHipAsync', wrapperMoveOneHipAsync);     

    const wrapperTiltBodyAsync = interpreter.createAsyncFunction(
      function (direction, targetAngle, callback) {      
        intervalPid.current = setInterval(function(){      
          if(isDoingMotionRef.current){
            Logger(`stop motion for new body roll motion`);
            robotRef.current.stopMotion();
          } 
          if(isMovingHipRef.current){
            Logger(`stop hip for new body roll motion`);
            robotRef.current.stopHip();
          }
          if(!isDoingMotionRef.current && !isMovingHipRef.current) {
            Logger(`body roll`);
            isMovingHipRef.current = true;
            robotRef.current.tiltBody(direction, targetAngle);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)       
    });
    interpreter.setProperty(globalObject, 'tiltBodyAsync', wrapperTiltBodyAsync); 

    const wrapperHipMotionFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isFinished = !isMovingHipRef.current;                
          if(isFinished) {            
            Logger('hip motion finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'hipMotionFinishChecker', wrapperHipMotionFinishChecker);        

    const wrapperStopHip = interpreter.createNativeFunction(
      function () {       
        // Logger(`stopHip()`);
        return robotRef.current.stopHip();
    });
    interpreter.setProperty(globalObject, 'stopHip', wrapperStopHip);

    const wrapperSpinDegrees = interpreter.createNativeFunction(
      function (direction, angle, selectedSpeed) {             
        return robotRef.current.spinDegrees(direction, angle, selectedSpeed);
    });
    interpreter.setProperty(globalObject, 'spinDegrees', wrapperSpinDegrees); 

    const wrapperSpinDegreesAsync = interpreter.createAsyncFunction(
      function (direction, angle, selectedSpeed, callback) {           
        const task = 'move_spin'  
        intervalPid.current = setInterval(function(){    
          if(isDoingMotionRef.current){
            Logger(`stop motion for ${task} task`);
            robotRef.current.stopMotion();
          } 
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for ${task} task`);
            robotRef.current.cancelNaviTask();
          }
          if(runningWheelTaskRef.current !== ''){
            Logger(`cancel previous wheel task for ${task} task`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(!isDoingMotionRef.current && !isRunningNaviTaskRef.current && runningWheelTaskRef.current === '') {            
            Logger(`start ${task} task`);
            runningWheelTaskRef.current = task;
            robotRef.current.spinDegrees(direction, angle, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)                     
    });
    interpreter.setProperty(globalObject, 'spinDegreesAsync', wrapperSpinDegreesAsync); 

    const wrapperMoveDistance = interpreter.createNativeFunction(
      function (direction, distance, selectedSpeed) {            
        return robotRef.current.moveDistance(direction, distance, selectedSpeed);
    });
    interpreter.setProperty(globalObject, 'moveDistance', wrapperMoveDistance); 

    const wrapperMoveDistanceAsync = interpreter.createAsyncFunction(
      function (direction, distance, selectedSpeed, callback) {            
        const task = direction === 'forward' ? 'move_forward' : 'move_backward';
        intervalPid.current = setInterval(function(){    
          if(isDoingMotionRef.current){
            Logger(`stop motion for ${task} task`);
            robotRef.current.stopMotion();
          } 
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for ${task} task`);
            robotRef.current.cancelNaviTask();
          }
          if(runningWheelTaskRef.current !== ''){
            Logger(`cancel previous wheel task for ${task} task`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(!isDoingMotionRef.current && !isRunningNaviTaskRef.current && runningWheelTaskRef.current === '') {            
            Logger(`start ${task} task`);
            runningWheelTaskRef.current = task;
            robotRef.current.moveDistance(direction, distance, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)         
    });
    interpreter.setProperty(globalObject, 'moveDistanceAsync', wrapperMoveDistanceAsync);     

    const wrapperMoveAlongTurningRadius = interpreter.createNativeFunction(
      function (direction, angle, radius, selectedSpeed) {                
        return robotRef.current.moveAlongTurningRadius(direction, angle, radius, selectedSpeed);
    });
    interpreter.setProperty(globalObject, 'moveAlongTurningRadius', wrapperMoveAlongTurningRadius);     

    const wrapperMoveAlongTurningRadiusAsync = interpreter.createAsyncFunction(
      function (direction, angle, radius, selectedSpeed, callback) {                
        const task = 'move_along_turning_radius';
        intervalPid.current = setInterval(function(){    
          if(isDoingMotionRef.current){
            Logger(`stop motion for ${task} task`);
            robotRef.current.stopMotion();
          } 
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for ${task} task`);
            robotRef.current.cancelNaviTask();
          }
          if(runningWheelTaskRef.current !== ''){
            Logger(`cancel previous wheel task for ${task} task`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(!isDoingMotionRef.current && !isRunningNaviTaskRef.current && runningWheelTaskRef.current === '') {            
            Logger(`start ${task} task`);
            runningWheelTaskRef.current = task;
            robotRef.current.moveAlongTurningRadius(direction, angle, radius, selectedSpeed);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'moveAlongTurningRadiusAsync', wrapperMoveAlongTurningRadiusAsync);     
    
    const wrapperWheelMoveFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isFinished = runningWheelTaskRef.current === '';             
          if(isFinished) {            
            Logger('wheel move finished.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'wheelMoveFinishChecker', wrapperWheelMoveFinishChecker);    

    const wrapperStopWheel = interpreter.createNativeFunction(
      function () {       
        // Logger(`stopWheel()`);
        return robotRef.current.stopWheel();
    });
    interpreter.setProperty(globalObject, 'stopWheel', wrapperStopWheel);

    const wrapperStopWheelAsync = interpreter.createAsyncFunction(
      function (callback) {       
        intervalPid.current = setInterval(function(){    
          if(runningWheelTaskRef.current !== ''){
            Logger(`stop wheel task ${runningWheelTaskRef.current}`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(runningWheelTaskRef.current === '') {            
            Logger(`stop wheel done`);            
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'stopWheelAsync', wrapperStopWheelAsync);

    const wrapperStopAllMovement = interpreter.createNativeFunction(
      function () {       
        return robotRef.current.stopAllMovement();
    });
    interpreter.setProperty(globalObject, 'stopAllMovement', wrapperStopAllMovement);

    const wrapperStopAllMovementAsync = interpreter.createAsyncFunction(
      function (callback) {       
        intervalPid.current = setInterval(function(){    
          if(isMovingHeadsetRef.current){
            Logger(`stop headset for stopping all movement`);
            robotRef.current.stopHeadset();
          }
          if(isMovingHipRef.current){
            Logger(`stop hip for stopping all movement`);
            robotRef.current.stopHip();
          }
          if(isDoingMotionRef.current){
            Logger(`stop motion for stopping all movement`);
            robotRef.current.stopMotion();
          } 
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for stopping all movement`);
            robotRef.current.cancelNaviTask();
          }
          if(runningWheelTaskRef.current !== ''){
            Logger(`cancel previous wheel task for stopping all movement`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(!isMovingHeadsetRef.current && !isMovingHipRef.current && !isDoingMotionRef.current && !isRunningNaviTaskRef.current && runningWheelTaskRef.current === '') {            
            Logger(`stopping all movement done`);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'stopAllMovementAsync', wrapperStopAllMovementAsync);

    const wrapperInitPose = interpreter.createNativeFunction(
      function () {       
        return robotRef.current.initPose();
    });
    interpreter.setProperty(globalObject, 'initPose', wrapperInitPose);   

    const wrapperInitPoseAsync = interpreter.createAsyncFunction(
      function (callback) {       
        const task = 'init_pose'
        intervalPid.current = setInterval(function(){    
          if(isMovingHeadsetRef.current){
            Logger(`stop headset for ${task} task`);
            robotRef.current.stopHeadset();
          }
          if(isMovingHipRef.current){
            Logger(`stop hip for ${task} task`);
            robotRef.current.stopHip();
          }
          if(isDoingMotionRef.current){
            Logger(`stop motion for ${task} task`);
            robotRef.current.stopMotion();
          } 
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for ${task} task`);
            robotRef.current.cancelNaviTask();
          }
          if(runningWheelTaskRef.current !== ''){
            Logger(`cancel previous wheel task for ${task} task`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);
          }
          if(!isMovingHeadsetRef.current && !isMovingHipRef.current && !isDoingMotionRef.current && !isRunningNaviTaskRef.current && runningWheelTaskRef.current === '') {            
            Logger(`start ${task} task`);
            isMovingHeadsetRef.current = true;
            isMovingHipRef.current = true;
            robotRef.current.initPose();
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'initPoseAsync', wrapperInitPoseAsync); 

    const wrapperInitPoseFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isFinished = !isMovingHeadsetRef.current && !isMovingHipRef.current;             
          if(isFinished) {            
            Logger('robot is on init pose.');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'initPoseFinishChecker', wrapperInitPoseFinishChecker);   

    // sensing blocks
    const wrapperStartWakewordDetection = interpreter.createNativeFunction(
      function () {                
        return robotRef.current.startWakewordDetection();  
    });
    interpreter.setProperty(globalObject, 'startWakewordDetection', wrapperStartWakewordDetection);

    const wrapperCheckIfWakewordDetected = interpreter.createNativeFunction(
      function () {              
        if (lastTimeWakewordDetectedRef.current) {
          let isWakeWordDetected;
          const now = Date.now();
          const lastTime = lastTimeWakewordDetectedRef.current;
          lastTimeWakewordDetectedRef.current = null;
          const elapsedTimeSeconds = Math.floor((now - lastTime) / 1000);
          elapsedTimeSeconds < detectTimeDuration ? isWakeWordDetected = true : isWakeWordDetected = false;
          Logger(`elapsedTimeSeconds ${elapsedTimeSeconds} = now ${Math.floor(now / 1000)} - lastTime ${Math.floor(lastTime / 1000)}`);
          Logger(`isWakeWordDetected: ${isWakeWordDetected}`);
          if(isWakeWordDetected) return true; 
        } 
        return false;
    });
    interpreter.setProperty(globalObject, 'checkIfWakewordDetected', wrapperCheckIfWakewordDetected);

    const wrapperStopWakewordDetection = interpreter.createNativeFunction(
      function () {                
        return robotRef.current.stopWakewordDetection();  
    });
    interpreter.setProperty(globalObject, 'stopWakewordDetection', wrapperStopWakewordDetection);

    const wrapperUpdateRecentValue = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          Logger('update recent value');
          clearInterval(intervalPid.current);
          callback(function(){return true});
        }, updateRecentValueTimeout)        
    });
    interpreter.setProperty(globalObject, 'updateRecentValue', wrapperUpdateRecentValue);  

    const wrapperStartPersonDetection = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startPersonDetection();  
    });
    interpreter.setProperty(globalObject, 'startPersonDetection', wrapperStartPersonDetection);   

    const wrapperCheckIfPersonDetected = interpreter.createNativeFunction(
      function () {          
        const isPersonDetected = personDetectedRef.current;
        personDetectedRef.current = null;          
        Logger(`isPersonDetected: ${isPersonDetected}`);
        return isPersonDetected;
    });
    interpreter.setProperty(globalObject, 'checkIfPersonDetected', wrapperCheckIfPersonDetected);

    const wrapperPersonDetectionFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          const isDetected = personDetectedRef.current;
          if(isDetected !== null) {
            Logger('person detection finished');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'personDetectionFinishChecker', wrapperPersonDetectionFinishChecker);

    // const wrapperStopPersonDetection = interpreter.createNativeFunction(
    //   function () {      
    //     return robotRef.current.stopPersonDetection();  
    // });
    // interpreter.setProperty(globalObject, 'stopPersonDetection', wrapperStopPersonDetection);     

    const wrapperStartFaceDetection = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startFaceDetection();  
    });
    interpreter.setProperty(globalObject, 'startFaceDetection', wrapperStartFaceDetection);

    const wrapperGetDetectedFaceList = interpreter.createNativeFunction(
      function (userIdx) {          
        const detectedFaceList = faceDetectedUserListRef.current;
        faceDetectedUserListRef.current = null;
        Logger(`getDetectedFaceList: ${detectedFaceList}`);     
        let isDetected = false;
        if(detectedFaceList.length === 0) isDetected = false;
        else if(userIdx === 0) isDetected = true;
        else {
          detectedFaceList.forEach((user) => {
            if(user.userId === userIdx) isDetected = true;
          })
        }   
        Logger(`isDetected: ${isDetected}`);     
        return isDetected;
    });
    interpreter.setProperty(globalObject, 'getDetectedFaceList', wrapperGetDetectedFaceList);

    const wrapperGetNumberOfFaceDetected = interpreter.createNativeFunction(
      function () {          
        const detectedFaceList = faceDetectedUserListRef.current;
        faceDetectedUserListRef.current = null;
        Logger(`detectedFaceList: ${detectedFaceList}, numberOfFaceDetected: ${detectedFaceList.length}`);     
        return detectedFaceList.length;
    });
    interpreter.setProperty(globalObject, 'getNumberOfFaceDetected', wrapperGetNumberOfFaceDetected);

    const wrapperFaceDetectionFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          let isFinished;
          faceDetectedUserListRef.current ? isFinished = true : isFinished = false;
          if(isFinished) {
            Logger('face detection finished');
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'faceDetectionFinishChecker', wrapperFaceDetectionFinishChecker);

    // let wrapperStartFalldownDetection = interpreter.createNativeFunction(
    //   function () {      
    //     return robotRef.current.startFallDownDetection();  
    // });
    // interpreter.setProperty(globalObject, 'startFallDownDetection', wrapperStartFalldownDetection); 

    // let wrapperGetFalldownStatus = interpreter.createNativeFunction(
    //   function () {
    //     let isFalldown = isFalldownRef.current;
    //     isFalldownRef.current = null;
    //     return isFalldown;
    // });
    // interpreter.setProperty(globalObject, 'getFalldownStatus', wrapperGetFalldownStatus);

    // let wrapperStartPickupDetection = interpreter.createNativeFunction(
    //   function () {      
    //     return robotRef.current.startPickupDetection();  
    // });
    // interpreter.setProperty(globalObject, 'startPickupDetection', wrapperStartPickupDetection);

    // let wrapperGetPickupStatus = interpreter.createNativeFunction(
    //   function () {
    //     let isPickUp = isPickUpRef.current;
    //     isPickUpRef.current = null;
    //     return isPickUp;
    // });
    // interpreter.setProperty(globalObject, 'getPickupStatus', wrapperGetPickupStatus);

    // let wrapperSensingFinishChecker = interpreter.createAsyncFunction(
    //   function (elementId, callback) {                   
    //     intervalPid.current = setInterval(function(){    
    //       const isDetected = elementId === 'isFalldown' ? isFalldownRef.current : isPickupRef.current);
    //       if(isDetected !== null) {
    //         Logger(`sensing finished: ${elementId}, ${isDetected}`);
    //         clearInterval(intervalPid.current);
    //         callback();
    //       }
    //     }, finishCheckerInterval)        
    // });
    // interpreter.setProperty(globalObject, 'sensingFinishChecker', wrapperSensingFinishChecker);     
     
    const wrapperStartGetHeadsetAngle = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startGetHeadsetAngle();  
    });
    interpreter.setProperty(globalObject, 'startGetHeadsetAngle', wrapperStartGetHeadsetAngle); 

    const wrapperGetAngleOfHeadset = interpreter.createNativeFunction(
      function () {
        let value = angleOfHeadsetRef.current;
        angleOfHeadsetRef.current = null;
        return Number(value)/10;
      },
    );
    interpreter.setProperty(globalObject, 'getAngleOfHeadset', wrapperGetAngleOfHeadset);

    const wrapperStartGetHipAngle = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startGetHipAngle();  
    });
    interpreter.setProperty(globalObject, 'startGetHipAngle', wrapperStartGetHipAngle); 

    const wrapperGetAngleOfHip = interpreter.createNativeFunction(
      function (direction) {	
        const value = direction === 'left' ? angleOfLeftHipRef.current : angleOfRightHipRef.current;
        angleOfLeftHipRef.current = null;
        angleOfRightHipRef.current = null;
        return Number(value)/10;
      },
    );
    interpreter.setProperty(globalObject, 'getAngleOfHip', wrapperGetAngleOfHip);

    const wrapperStartGetWheelSpeed = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.startGetWheelSpeed();  
    });
    interpreter.setProperty(globalObject, 'startGetWheelSpeed', wrapperStartGetWheelSpeed); 

    const wrapperGetSpeedOfWheel = interpreter.createNativeFunction(
      function (direction) {	
        const value = direction === 'left' ? speedOfLeftWheelRef.current : speedOfRightWheelRef.current;
        speedOfLeftWheelRef.current = null;
        speedOfRightWheelRef.current = null;
        return Number(value);
      },
    );
    interpreter.setProperty(globalObject, 'getSpeedOfWheel', wrapperGetSpeedOfWheel);

    const wrapperStartGetBatteryInfo = interpreter.createNativeFunction(
      function () {      
        Logger(`battery level: ${batteryLevelRef.current}`);
        return robotRef.current.startGetBatteryInfo();  
    });
    interpreter.setProperty(globalObject, 'startGetBatteryInfo', wrapperStartGetBatteryInfo); 

    const wrapperGetBatteryLevel = interpreter.createNativeFunction(
      function () {
        let value = batteryLevelRef.current;        
        batteryLevelRef.current = null;
        return Number(value);
      },
    );
    interpreter.setProperty(globalObject, 'getBatteryLevel', wrapperGetBatteryLevel);

    const wrapperGetValueFinishChecker = interpreter.createAsyncFunction(
      function (elementId, callback) {                   
        intervalPid.current = setInterval(function(){  
          let value;
          switch(elementId){
            case 'angleOfHeadset':
              value = angleOfHeadsetRef.current;              
              break;
            case 'angleOfHip':
              value = angleOfLeftHipRef.current;              
              break;
            case 'speedOfWheel':
              value = speedOfLeftWheelRef.current;              
              break;
            case 'batteryLevel':
              value = batteryLevelRef.current;              
              break;
            default:
          } 
          if(value !== null){
            Logger(`getting value finished: ${elementId}, ${value}`);
            clearInterval(intervalPid.current);
            callback();
          }        
        }, finishCheckerInterval)        
    });
    interpreter.setProperty(globalObject, 'getValueFinishChecker', wrapperGetValueFinishChecker);     

    const wrapperCheckIfAllMovementStopped = interpreter.createNativeFunction(
      function () {                   
        Logger(`check if all movement stopped`);
        if(!isMovingHeadsetRef.current 
          && !isMovingHipRef.current 
          && !isDoingMotionRef.current 
          && !isRunningNaviTaskRef.current 
          && runningWheelTaskRef.current === '') return true
        else return false;
    });
    interpreter.setProperty(globalObject, 'checkIfAllMovementStopped', wrapperCheckIfAllMovementStopped);

    const wrapperCheckIfIsDoingNaviTask = interpreter.createNativeFunction(
      function () {                   
        Logger(`check if is doing navigation task`);
        if(isRunningNaviTaskRef.current || runningWheelTaskRef.current !== '') return true
        else return false;
    });
    interpreter.setProperty(globalObject, 'checkIfIsDoingNaviTask', wrapperCheckIfIsDoingNaviTask);

    // navigation blocks
    const wrapperStartNaviTaskAsync = interpreter.createAsyncFunction(
      function (task, idx, callback) {      
        intervalPid.current = setInterval(function(){    
          if(isDoingMotionRef.current){
            Logger(`stop motion for starting navi task ${task}`);
            robotRef.current.stopMotion();
          } 
          if(runningWheelTaskRef.current !== ''){
            Logger(`stop previous wheel task for new navi task ${task}`);
            robotRef.current.stopWheel(runningWheelTaskRef.current);;
          }
          if(isRunningNaviTaskRef.current){
            Logger(`cancel previous navi task for new navi task ${task}`);
            robotRef.current.cancelNaviTask();
          }
          if(!isDoingMotionRef.current && runningWheelTaskRef.current === '' && !isRunningNaviTaskRef.current) {            
            Logger(`start navi task ${task}`);
            isRunningNaviTaskRef.current = true;
            robotRef.current.startNaviTask(task, idx);
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)             
    });
    interpreter.setProperty(globalObject, 'startNaviTaskAsync', wrapperStartNaviTaskAsync);

    const wrapperCancelNaviTask = interpreter.createNativeFunction(
      function () {      
        return robotRef.current.cancelNaviTask();
    });
    interpreter.setProperty(globalObject, 'cancelNaviTask', wrapperCancelNaviTask);

    const wrapperCancelNaviTaskAsync = interpreter.createAsyncFunction(
      function (callback) {       
        intervalPid.current = setInterval(function(){    
          if(isRunningNaviTaskRef.current && !isStoppingNaviTaskRef.current){
            Logger(`cancel navi task`);
            isStoppingNaviTaskRef.current = true;
            robotRef.current.cancelNaviTask();
          }
          if(!isRunningNaviTaskRef.current) {            
            Logger(`cancel navi task done`);
            isStoppingNaviTaskRef.current = false;
            clearInterval(intervalPid.current);
            callback();
          }
        }, startAsyncInterval)  
    });
    interpreter.setProperty(globalObject, 'cancelNaviTaskAsync', wrapperCancelNaviTaskAsync);

    const wrapperNaviTaskFinishChecker = interpreter.createAsyncFunction(
      function (callback) {                   
        intervalPid.current = setInterval(function(){    
          let isNaviTaskFinished = !isRunningNaviTaskRef.current;
          if(isNaviTaskFinished) {            
            Logger(`navi task finished.`);
            clearInterval(intervalPid.current);
            callback();
          }
        }, finishCheckerInterval)    
    });
    interpreter.setProperty(globalObject, 'naviTaskFinishChecker', wrapperNaviTaskFinishChecker);  

    // loop blocks
    const wrapperSetLoopTrapCounter = interpreter.createNativeFunction(
      function () {                
        loopTrapCounterRef.current = loopTrapCountNumber;
        Logger(`set loop trap counter: ${loopTrapCounterRef.current}`);
        return;
      },
    );
    interpreter.setProperty(globalObject, 'setLoopTrapCounter', wrapperSetLoopTrapCounter);
    
    const wrapperCheckLoopTrapCounter = interpreter.createNativeFunction(
      function () {						
        Logger(`loop trap counter: ${--loopTrapCounterRef.current}`);
        return;
      },
    );
    interpreter.setProperty(globalObject, 'checkLoopTrapCounter', wrapperCheckLoopTrapCounter);

    const wrapperForceStopInterpreter = interpreter.createAsyncFunction(
      function (callback) {						
        intervalPid.current = setInterval(function(){    
          if(loopTrapCounterRef.current <= 0) {
            Logger('Code is trapped in infinite loop. Terminate code');	
            isTrappedInInfiniteLoop.current = true;            
          } else {
            clearInterval(intervalPid.current);
            callback();
          }          
        }, 0)
      },
    );
    interpreter.setProperty(globalObject, 'forceStopInterpreter', wrapperForceStopInterpreter);
           
    // Add an API function for highlighting blocks.
    const wrapperHighlight = interpreter.createAsyncFunction(
      function (id, addSelect, callback) {       
        id = String(id || '');
        highlightBlock(id, addSelect);
        intervalPid.current = setInterval(function(){   
          clearInterval(intervalPid.current); 
          callback();
        }, highlightBlockMinDuration)        
    });
    interpreter.setProperty(globalObject, 'highlightBlock', wrapperHighlight);

    // Add an API for the wait block.  See ./Generators/logic.js
    logicGenerator.initInterpreterWaitForSeconds(interpreter, globalObject);  
    developerGenerator.initInterpreterAlert(interpreter, globalObject);
  }

  function highlightBlock(id, addSelect) {    
    // apply unhighlight and remove select ui to all blocks
    workspace.current.highlightBlock(null);   
    let blocks = workspace.current.getAllBlocks(false);
    blocks.forEach((block) => block.removeSelect());
    // apply highlight and add select ui to this block
    const thisBlock = workspace.current.getBlockById(id);
    if(addSelect) thisBlock.addSelect();            
    workspace.current.highlightBlock(id, true);                
  }

  // highlight javascript code in paper component for code view
  // function highlightCode() {
  //   const nodes = document.querySelectorAll('pre code');
  //   nodes.forEach(node => hljs.highlightElement(node));    
  // }

  useEffect(() => {
    if (generatedCode === '' || viewCode === false) return;
    const element = document.getElementsByClassName('javascript');
    if (element[0] && element[0].hasAttribute('data-highlighted')) {
      element[0].removeAttribute('data-highlighted');
    }
    hljs.highlightElement(element[0]);
  }, [generatedCode, viewCode])

  // highlight cpp code snippet in paper component for code view
  useEffect(() => {
    if (apiInfoData === '' || viewAPIInfoData === false) return;
    const element = document.getElementsByClassName('cpp');
    if (element[0] && element[0].hasAttribute('data-highlighted')) {
      element[0].removeAttribute('data-highlighted');
    }
    hljs.highlightElement(element[0]);    
  }, [apiInfoData, viewAPIInfoData])

  function resetBlockUi() {
    workspace.current.highlightBlock(null);        
    let blocks = workspace.current.getAllBlocks(false);
    blocks.forEach((block) => {
      block.removeSelect();        
    })        
  }

  function initStates(){
    clearTimeout(runnerPid.current);      
    clearInterval(intervalPid.current);
    isTrappedInInfiniteLoop.current = false;

    textFromSpeechRef.current = '';    
    // isRecordingFinishedRef.current = false;    
    lastTimeWakewordDetectedRef.current = null;
    personDetectedRef.current = null;
    faceDetectedUserListRef.current = null;
    // isFalldownRef.current = null;
    // isPickUpRef.current = null;
    angleOfHeadsetRef.current = null;
    angleOfLeftHipRef.current = null;
    angleOfRightHipRef.current = null;
    speedOfLeftWheelRef.current = null;
    speedOfRightWheelRef.current = null;
    batteryLevelRef.current = null;
    isRunningNaviTaskRef.current = false;
    isSucceededPrepareNaviRef.current = null;
    isSucceededCreateMapRef.current = null;
    isSucceededGoHomeRef.current = null;
    isSucceededDockToChargerRef.current = null;
    isSucceededUndockFromChargerRef.current = null;
    isSucceededExploreNodesRef.current = null;
    isSucceededNaviToSpaceRef.current = null;
    isSucceededSpinToFindPersonRef.current = null;
    isSucceededEyeContactRef.current = null;
    isSucceededComeHereRef.current = null;
    isSucceededFollowMeRef.current = null;

    isRepeatingFaceAnimationRef.current = false;
    playingSoundTypeRef.current = '';
    isDoingMotionRef.current = false;
    isMovingHeadsetRef.current = false;
    isMovingHipRef.current = false;
    runningWheelTaskRef.current = '';
    isRunningNaviTaskRef.current = false;
  }  

  function runGeneratedCode() {
    myInterpreter.current = new Interpreter(generatedCode, initApi);
    Logger('<< myInterpreter run >>');
    function runner() {
      if (myInterpreter.current) {        
        const hasMore = myInterpreter.current.run();
        if (hasMore) {  
          if (isTrappedInInfiniteLoop.current){            
            forceStop('infiniteloop');              
          } else {
            Logger('<< re run runner >>');
            // Execution is currently blocked by some async call. Try again.
            runnerPid.current = setTimeout(runner, 10);
          }          
        } else {     // Program completed.          
          programCompleted();
        }
      }
    }
    runner();
  }  

  function runCode() {    
    if (myInterpreter.current) return;
    Logger('Program output:\n=================');
    setIsRunning(true);  
    initStates();      
    runGeneratedCode();
  } 

  function stopCode(withInitPose){    
    function stopper(){
      Logger('<< Reset UI and initialize pose and states done >>');       
      setIsRunning(false);    
      setIsStopping(false);
      resetBlockUi();
      initStates();    
    }
    if(withInitPose){
      const intervalID = setInterval(function(){    
        if(!isMovingHeadsetRef.current && !isMovingHipRef.current) {                    
          clearInterval(intervalID);               
          stopper();
        }        
      }, stopCheckerInterval)     
    } else {
      stopper();
    }    
  }

  // face expression loop, play sound(effect, file, tts), motion, headset, hip, wheel(move forward/backward, spin, move along turning radius, navigation)
  function checkIfRobotStopped() {
    if(playingSoundTypeRef.current === '' &&
      // !isRepeatingFaceAnimationRef.current &&
      !isDoingMotionRef.current && 
      !isMovingHeadsetRef.current && 
      !isMovingHipRef.current &&
      runningWheelTaskRef.current === '' &&
      !isRunningNaviTaskRef.current) return true;

    return false
  }

  function initializePose(){
    isMovingHeadsetRef.current = true;      
    isMovingHipRef.current = true;      
    robotRef.current.initPose();         // initialize robot pose
  }

  function programCompleted(){          
    // setIsStopping(true);                
    const intervalID = setInterval(function(){    
      if(checkIfRobotStopped()) {   
        Logger('<< Program completed >>');
        if(isRepeatingFaceAnimationRef.current) isRepeatingFaceAnimationRef.current = false;                      
        robotRef.current.playFacialExpression(85, 0);
        clearInterval(intervalID);
        myInterpreter.current = null;  
        initializePose();
        stopCode(true);             
      }
    }, stopCheckerInterval)  
  } 

  // face expression loop, play sound(effect, file, tts), motion, headset, hip, wheel(move forward/backward, spin, move along turning radius), navigation
  function stopAllRobotOperation(){   
    if(isRepeatingFaceAnimationRef.current) isRepeatingFaceAnimationRef.current = false;
    robotRef.current.playFacialExpression(85, 0);
    // need to add logic for stopping sound
    if(playingSoundTypeRef.current !== '') robotRef.current.stopSound(playingSoundTypeRef.current);
    if(isDoingMotionRef.current) robotRef.current.stopMotion();
    if(isMovingHeadsetRef.current) robotRef.current.stopHeadset();
    if(isMovingHipRef.current) robotRef.current.stopHip();
    if(runningWheelTaskRef.current !== '') robotRef.current.stopWheel(runningWheelTaskRef.current);
    if(isRunningNaviTaskRef.current) robotRef.current.cancelNaviTask();
  }

  function forceStop(reason){    
    myInterpreter.current = null; 
    stopAllRobotOperation();
    setIsStopping(true);   
    const intervalID = setInterval(function(){    
      if(checkIfRobotStopped()) {       
        clearInterval(intervalID);         
        switch(reason){          
          case 'force':
            Logger('<< Force stop program >>');  
            initializePose();
            stopCode(true);
            break;
          case 'infiniteloop':
            Logger('<< Program terminated because of infinite loop >>');
            initializePose();
            stopCode(true);
            setOpenInfiniteLoopDialog(true);    
            break;
          case 'emergency':
            Logger('<< Emergency Stop Program >>');      
            stopCode(false);
            break;
          default:
        }                    
      }
    }, stopCheckerInterval)     
  }  

  const handleDisconnectRobot = () => {
    Logger('Disconnect robot.');		
    robotRef.current.disconnectRobot(); 
  }

  const handleCloseNotReady = () => {
    Logger('Connection setup is required. Go to Home');		
    setOpenNotReadyDialog(false);
    setIsReadyContext(false);
    setRobotIPContext('');
    setImageFileList([]);
    setAudioFileList([]);
    setRegisteredUserList([]);
    setSpaceList([]);
    navigate('/');
  }

  const handleCloseInfiniteLoopDialog = () => {
    setOpenInfiniteLoopDialog(false);
  }

// using wss  
  const handleSubmitSendFile = (e) => {        
    e.preventDefault();    
    if(filesToSend.length === 0) {
      setOpenNoSelectedFile(true);
      return;
    }     
    setLoadingSendFile(true);
    try {      
      robotRef.current.sendFiles(fileType, filesToSend);
		} catch (error) {      
      setLoadingSendFile(false);
			Logger(`Error while sending files ${error}`);
		}      
  }

  const handleSendFileResult = () => {
    setLoadingSendFile(false);
    Logger('successfully sent files');
    handleCloseSendFile();
    setOpenSendFileSuccess(true);
  }

  const handleCloseSendFile = () => {
    setFileType('');
    setOpenSendFileDialog(false);
    setOpenNoSelectedFile(false);
    setFilesToSend([]);
  }

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setOpenCopyToClipboardSuccess(true);
    } catch (e) {
      setOpenCopyToClipboardFail(true);
    }
};

  return (
    <>
    <RootStyle>
      <Container       
        disableGutters
        ref={blocklyDiv}
        maxWidth={false} 
        sx={{    
          // height: 'calc(100vh - 52px)',
          height: 'calc(100vh)',
          width: '100%'
        }}
      ></Container>
           
      <Stack 
        spacing={2} 
        direction="row" 
        sx={{ top: '35px', right: '130px', position: 'absolute', display: 'flex' }}
      >
        { /* <StyledButton variant="outlined" color="secondary" sx={{ fontSize: '13px' }} onClick={() => setViewCode(!viewCode)}>  
          Code view
        </StyledButton>
        <StyledButton variant="outlined" color="secondary" sx={{ fontSize: '13px' }} onClick={() => setViewCppCodeSnippet(!viewCppCodeSnippet)}>  
          Cpp Code view
        </StyledButton> */ }
        <StyledTooltip title="Send files to your robot" placement="bottom-start">
          <StyledButton 
            variant="outlined" 
            color="secondary" 
            disabled={isRunning || isStopping}
            onClick={() => setOpenSendFileDialog(!openSendFileDialog)}>  
            Send files
          </StyledButton>       
        </StyledTooltip>
        <StyledTooltip title="Save this workspace to local storage in your browser" placement="bottom-start">
          <StyledButton 
            variant="outlined" 
            color="secondary" 
            disabled={isRunning || isStopping}
            onClick={() => setOpenSaveDialog(true)}>
            Save
          </StyledButton>          
        </StyledTooltip>         
        <StyledTooltip title="Run or stop the code" placement="bottom-start">
        <StyledButton 
          variant="contained" 
          color="secondary" 
          disabled={isStopping}
          onClick={() => isRunning ? forceStop('force') : runCode() }
          >
          {runBtnMsg}
        </StyledButton>
        </StyledTooltip>
        <StyledTooltip title="Immediately stop the code and robot movement." placement="bottom-start">
        <StyledButton 
          variant="contained" 
          color="error"           
          onClick={() => forceStop('emergency')}
          sx={{ width: "120px", fontSize: "12px" }}>
          Emergency Stop
        </StyledButton>
        </StyledTooltip>
        <StyledTooltip title="Disconnect robot and return robot to normal mode" placement="bottom-start">
        <StyledButton 
          variant="outlined" 
          color="error" 
          disabled={isRunning || isStopping}
          onClick={() => setOpenDisconnectRobotDialog(true)}>
          Disconnect
        </StyledButton>
        </StyledTooltip>
      </Stack>      
      <Stack 
        direction="row" 
        sx={{ top: '75px', right: '1px', position: 'absolute', display: 'flex' }}
      >
        <StyledTooltip title="Save your blocks here" placement="bottom-start">
          <StyledButton 
            variant="text" 
            color="inherit"
            size="small"
            disableElevation
            disableRipple
            disableFocusRipple
            sx={{             
              fontSize: '12px',
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
            }}}
          >
            Backpack
        </StyledButton>
        </StyledTooltip>
      </Stack>      
    </RootStyle>

    <Dialog
      open={openNotReadyDialog}
      onClose={handleCloseNotReady}
      PaperProps={{
        component: 'form',
        onSubmit: (e) => {
          e.preventDefault();          
          handleCloseNotReady();
        },
      }}
    >
      <DialogTitle>Connection setup is required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You need to first set up the connection with your robot.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color='secondary'>Go to Home</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openDisconnectRobotDialog}
      onClose={() => setOpenDisconnectRobotDialog(false)}
      PaperProps={{
        component: 'form',
        onSubmit: (e) => {
          e.preventDefault();     
          setOpenDisconnectRobotDialog(false);     
          handleDisconnectRobot();          
        },
      }}
    >
      <DialogTitle>Disconnect robot</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to disconnect your robot?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color='secondary'>Disconnect</Button>
        <Button color='secondary' onClick={() => setOpenDisconnectRobotDialog(false)}>Cancel</Button>    
      </DialogActions>
    </Dialog>

    <Dialog
      open={openInfiniteLoopDialog}
      onClose={handleCloseInfiniteLoopDialog}
      PaperProps={{
        component: 'form',
        onSubmit: (e) => {
          e.preventDefault();          
          handleCloseInfiniteLoopDialog();
        },
      }}
    >
      <DialogTitle>Trapped in infinite loop</DialogTitle>
      <DialogContent>
        <DialogContentText>
         Your code is trapped in infinite loop. Terminate code execution.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color='secondary'>Terminate Code</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openSendFileDialog}
      onClose={handleCloseSendFile}
      PaperProps={{
        component: 'form',
        // onSubmit: (e) => handleSubmitSendFile(e)
      }}
    >
      <DialogTitle>Send files to your robot</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Select files and click send button. 
          After sending files, you can play them using display image file block 
          and play sound file block.
        </DialogContentText>                
        <List dense>
        {filesToSend.map((file) => (
          <ListItem key={file.name}>
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary={file.name} />                      
          </ListItem>        
        ))}        
        </List>
        {openNoSelectedFile &&
          <Typography variant="subtitle2" color='error.main'>
            Please select files to send            
          </Typography>
        }              
      </DialogContent>
      <DialogActions sx={{ justifyContent:'center', mx: 2 }}>
      <Grid container direction='row'>
      <Grid item xs={3} container alignItems="center" justifyContent="center" sx={{ p: 1}}>
        <FormControl color="secondary" size="small" sx={{ mx: 2 }}>
          <RadioGroup
            aria-labelledby="file-type-label"
            value={fileType}
            name="radio-file-type"
            onChange={(e) => setFileType(e.target.value)}
          >
            <FormControlLabel value="image" disabled={fileType !== ''} control={<Radio size="small" color="secondary"/>} label="image" sx={{ fontSize: '0.5rem' }}/>
            <FormControlLabel value="audio" disabled={fileType !== ''} control={<Radio size="small" color="secondary"/>} label="audio" sx={{ color:"secondary" }}/>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={5} container alignItems="center" justifyContent="left">
        <StyledButton
          variant="outlined"
          component="label"
          color='secondary'
          disabled={fileType === ''}
        >
          Select
          <input
            type="file"
            accept={fileType === 'image' ? "image/*" : "audio/*"}
            hidden
            multiple
            onChange={(e) => {
              if(IS_LOGGING_ON) console.log('selected files:', e.target.files);
              setOpenNoSelectedFile(false);
              setFilesToSend(Array.from(e.target.files));
            }}
          />
        </StyledButton>        
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="right">
        <LoadingButtonText 
          loading={loadingSendFile ? 1 : 0}
          onClick={handleSubmitSendFile}
        >
          {loadingSendFile && <Spinner size={24} />}
          Send
        </LoadingButtonText>
        <Button color='secondary' onClick={handleCloseSendFile}>Close</Button>
        </Grid>
        </Grid>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openSaveDialog}
      onClose={() => setOpenSaveDialog(false)}
      PaperProps={{
        component: 'form',
        onSubmit: (e) => {
          e.preventDefault();
          saveWorkspace(workspace.current, workspaceName);
          workspaceNameRef.current = workspaceName;
          if(IS_LOGGING_ON) console.log('save new workspace:', workspaceName);
          setOpenSaveWSSuccess(true);
          setOpenSaveDialog(false);
        },
      }}
    >
      <DialogTitle>Save Workspace</DialogTitle>
      <DialogContent>
        <DialogContentText>
          please enter the name of block code
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          fullWidth
          variant="standard"
          color="secondary"
          value={workspaceName}
          onChange={(e) => {
            setWorkspaceName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" color='secondary'>Save</Button>
        <Button color='secondary' onClick={() => setOpenSaveDialog(false)}>Cancel</Button>        
      </DialogActions>
    </Dialog>   

    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openSendFileSuccess}
      autoHideDuration={6000}
      onClose={() => setOpenSendFileSuccess(false)}
    >
      <Alert
        onClose={() => setOpenSendFileSuccess(false)}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Files sent successfully
      </Alert>
    </Snackbar>

    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openSaveWSSuccess}
      autoHideDuration={6000}
      onClose={() => setOpenSaveWSSuccess(false)}
    >
      <Alert
        onClose={() => setOpenSaveWSSuccess(false)}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Workspace saved successfully
      </Alert>
    </Snackbar>

    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openCopyToClipboardSuccess}
      autoHideDuration={6000}
      onClose={() => setOpenCopyToClipboardSuccess(false)}
    >
      <Alert
        onClose={() => {
          setOpenCopyToClipboardSuccess(false);
        }}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Successfully copied to clipboard
      </Alert>
    </Snackbar>

    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={openCopyToClipboardFail}
      autoHideDuration={6000}
      onClose={() => setOpenCopyToClipboardFail(false)}
    >
      <Alert
        onClose={() => setOpenCopyToClipboardFail(false)}
        severity="info"
        variant="filled"
        sx={{ width: '100%', fontFamily: 'Roboto', fontWeight: 200 }}
      >
        Copy to clipboard failed
      </Alert>
    </Snackbar>
      
    {viewCode &&
      generatedCode !== '' &&
      <Paper
        elevation={0}
        sx={{ 
          px: 1,        
          top: '120px',
          right: '220px',
          backgroundColor: 'white',
          position: 'absolute',
          fontSize: '11px',
          borderRadius: 0,
          borderColor: 'background.paper',
          borderStyle: 'solid',
          borderWidth: '0.1em',
          overflow: 'auto'
      }}>
        <pre><code className="javascript">
          {generatedCode}
        </code></pre>
      </Paper>
    }
    {viewAPIInfoData &&
      apiInfoData !== '' &&
      <Paper
        elevation={0}
        sx={{ 
          px: 1,        
          top: '120px',
          right: '200px',
          minHeight: '100px',
          minWidth: '500px',
          backgroundColor: 'white',
          position: 'absolute',
          fontSize: '12px',
          borderRadius: 0,
          borderColor: 'background.paper',
          borderStyle: 'solid',
          borderWidth: '0.1em',
          overflow: 'auto'
      }}>
        <IconButton 
          aria-label="close"
          onClick={() => setViewAPIInfoData(false)}
          sx={{position:"absolute", top: "5px", right: "10px"}}
        >          
          <CloseOutlinedIcon/>
        </IconButton>
        <IconButton 
          aria-label="close"
          onClick={() => handleCopyClipBoard(apiInfoData)}
          sx={{position:"absolute", bottom: "10px", right: "10px"}}
        >          
          <ContentCopyOutlinedIcon/>
        </IconButton>
        <pre><code className="cpp">
          {apiInfoData}
        </code></pre>
      </Paper>
    }
    {robotIPContext &&
      <WsConnector
        ip={robotIPContext}
        onCloseWssConnection={() => setOpenNotReadyDialog(true)}
        onSoundFinished={() => playingSoundTypeRef.current = '' }
        onSttFinished={(text) => textFromSpeechRef.current = text }
        // onVoiceRecordingFinished={() => isRecordingFinishedRef.current = true }
        onMotionFinished={() => isDoingMotionRef.current = false }
        onHeadsetMotionFinished={() => isMovingHeadsetRef.current = false }
        onHipMotionFinished={() => isMovingHipRef.current = false }
        onWheelMoveFinished={() => runningWheelTaskRef.current = ''}
        onUpdateWakeWordDetectionStatus={(receivedTime) => lastTimeWakewordDetectedRef.current = receivedTime }
        onUpdatePersonDetectionStatus={(isDetected) => personDetectedRef.current = isDetected }   
        onUpdateFaceDetectionStatus={(userList) => faceDetectedUserListRef.current = userList }   
        // onUpdateFalldownStatus={(isDetected) => isFalldownRef.current = isDetected }
        // onUpdatePickupStatus={(isDetected) => isPickUpRef.current = isDetected }
        onUpdateAngleOfHeadset={(angle) => angleOfHeadsetRef.current = angle }
        onUpdateAngleOfHip={(msg) => {
          angleOfLeftHipRef.current = msg.leftHip;
          angleOfRightHipRef.current = msg.rightHip;
        }}
        onUpdateSpeedOfWheel={(msg) => {
          speedOfLeftWheelRef.current = msg.leftWheel;
          speedOfRightWheelRef.current = msg.rightWheel;
        }}
        onUpdateBatteryLevel={(level) => batteryLevelRef.current = level }
        onUpdateNaviStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededPrepareNaviRef.current = isSucceeded;
        }}
        onUpdateCreateMapStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededCreateMapRef.current = isSucceeded;
        }}
        onUpdateIsAtHomeStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededGoHomeRef.current = isSucceeded;
        }}
        onUpdateDockToChargerStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededDockToChargerRef.current = isSucceeded;
        }}
        onUpdateUndockFromChargerStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededUndockFromChargerRef.current = isSucceeded;
        }}
        onUpdateExploreNodesStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededExploreNodesRef.current = isSucceeded;
        }}
        onUpdateNavigateToSpaceStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededNaviToSpaceRef.current = isSucceeded;
        }}
        onUpdateSpinToFindPersonStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededSpinToFindPersonRef.current = isSucceeded;
        }}
        onUpdateEyeContactStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededEyeContactRef.current = isSucceeded;
        }}
        onUpdateComeHereStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededComeHereRef.current = isSucceeded;
        }}
        onUpdateFollowMeStatus={(isSucceeded) => {
          isRunningNaviTaskRef.current = false;
          isSucceededFollowMeRef.current = isSucceeded;
        }}
        onUpdateNaviTaskCanceled={() => {
          isRunningNaviTaskRef.current = false;
          isSucceededPrepareNaviRef.current = null;
          isSucceededCreateMapRef.current = null;
          isSucceededGoHomeRef.current = null;
          isSucceededDockToChargerRef.current = null;
          isSucceededUndockFromChargerRef.current = null;
          isSucceededExploreNodesRef.current = null;
          isSucceededNaviToSpaceRef.current = null;
          isSucceededSpinToFindPersonRef.current = null;
          isSucceededEyeContactRef.current = null;
          isSucceededComeHereRef.current = null;
          isSucceededFollowMeRef.current = null;
        }}
        onUpdateSendFileResult={() => handleSendFileResult()}
        ref={(element) => robotRef.current = element}          
      />      
    }                    
  </>
  );
}

export default Workspace;
